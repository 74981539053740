import React, { useState, useEffect } from 'react';
import lightBlue from '@material-ui/core/colors/lightBlue';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';

import ChipField from '../common/ChipField';

import { getStatusSimCard } from '../../services/api';

const StatusField = ({ record, source, refresh, ...props }) => {
	const [status, setStatus] = useState();

	useEffect(() => {
		const getStatus = async () => {
			const response = await getStatusSimCard(record.id);
			setStatus(response.status);
		};

		if (record && refresh) {
			getStatus();
		}
		else {
			setStatus(record[source]);
		}
	}, [record, source, refresh]);

	if (!record || !status) {
		return null;
	}

	let color;
	switch (status) {
		case 'AVAILABLE':
		case 'ACTIVE':
			color = green[100];
			break;
		case 'RESERVED':
			color = lightBlue[100];
			break;
		case 'SUSPENDED':
			color = amber[100];
			break;
		case 'TERMINATED':
			color = red[100];
			break;
		default:
			break;
	}

	return (
		<ChipField source={source} record={{ [source]: status }} color={color} {...props} />
	);
};

StatusField.defaultProps = {
	source: 'status',
	label: 'Status',
	size: 'small',
	addLabel: true,
	refresh: false
};

export default StatusField;