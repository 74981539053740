import React from 'react';
import { useTranslate } from 'react-admin';
import lightBlue from '@material-ui/core/colors/lightBlue';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';

import ChipField from '../common/ChipField';

const StatusField = ({ classes, record, source, ...props }) => {
	const translate = useTranslate();

	if (!record) {
		return null;
	}

	const statuses = {
		'draft': translate('Draft'),
		'provisioning': translate('Provisioning'),
		'deployed': translate('Deployed'),
		'paused': translate('Paused'),
		'ended': translate('Ended'),
	};

	const status = record[source];

	let color;
	switch (status) {
		case 'deployed':
			color = green[100];
			break;
		case 'order':
		case 'confirmed':
			color = lightBlue[100];
			break;
		case 'paused':
		case 'provisioning':
			color = amber[100];
			break;
		case 'ended':
			color = red[100];
			break;
		default:
			break;
	}

	return (
		<ChipField source={status} record={statuses} color={color} {...props} />
	);
};

StatusField.defaultProps = {
	source: 'status',
	label: 'Status',
	addLabel: true
};

export default StatusField;
