import React, { useEffect, useState } from 'react';
import { useLocale, useSetLocale } from 'react-admin';
import Button from '@material-ui/core/Button';
import MUIMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LanguageIcon from '@material-ui/icons/Language';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { LANGUAGES } from '../../i18n/i18nProvider';

const LocalesMenuButton = ({ languages }) => {
    const locale = useLocale();
    const setLocale = useSetLocale();
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        const i18nextLng = localStorage.getItem('i18nextLng');
        if (i18nextLng) {
            setLocale(i18nextLng);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getNameForLocale = (locale) => {
        const language = languages.find(language => language.locale === locale);
        return language ? language.name : '';
    };

    const changeLocale = (locale) => () => {
        setLocale(locale);
        setAnchorEl(null);
        localStorage.setItem('i18nextLng', locale);
    };

    const handleLanguageClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                color="inherit"
                aria-controls="simple-menu"
                aria-label=""
                aria-haspopup="true"
                onClick={handleLanguageClick}
                startIcon={<LanguageIcon />}
                endIcon={<ExpandMoreIcon fontSize="small" />}
            >
                {getNameForLocale(locale)}
            </Button>
            <MUIMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {languages.map(language => (
                    <MenuItem
                        key={language.locale}
                        onClick={changeLocale(language.locale)}
                        selected={language.locale === locale}
                    >
                        {language.name}
                    </MenuItem>
                ))}
            </MUIMenu>
        </>
    );
};

LocalesMenuButton.defaultProps = {
    languages: LANGUAGES
};

export default LocalesMenuButton;