import React, { useState, useEffect } from 'react';
import { Edit as ReactAdminEdit, ListButton, ShowButton, useTranslate } from 'react-admin';

import CardActions from '../common/CardActions';
import Breadcrumb from '../common/Breadcrumb';

const EditActions = ({ basePath, className, data, hasList, hasShow, children, resource, breadcrumb }) => {
	const [icon, setIcon] = useState(null);
	const translate = useTranslate();

	useEffect(() => {
		async function getIcon() {
			try {
				const moduleResource = await import(`../${resource}`);
				if(moduleResource.default && moduleResource.default.icon) {
					setIcon(<moduleResource.default.icon />);
				}
			} catch(e) {
				console.error(e);
			}
		}

		getIcon();
	}, [resource]);

	const breadcrumbPath = [
		{ url: basePath, title: resource, isActive: false, icon },
		{
			url: data ? `${basePath}/${data.id}/show` : '',
			title: data ? (data.name ? data.name : data.id) : '',
			isActive: false
		},
		{
			url: data ? `${basePath}/${data.id}` : '',
			title: translate('ra.action.edit'),
			isActive: true
		}
	];

	return (
		<CardActions className={className}>
			<Breadcrumb path={breadcrumb || breadcrumbPath} />
			<div>
				{children}
				{hasList && <ListButton basePath={basePath} />}
				{hasShow && <ShowButton basePath={basePath} record={data} />}
			</div>
		</CardActions>
	);
};


const Edit = ReactAdminEdit;

Edit.defaultProps = {
	actions: <EditActions />,
	undoable: false
};

export default Edit;
export { EditActions };