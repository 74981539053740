import React from 'react';
import { Datagrid, TextField, DateInput, Pagination, ReferenceInput, AutocompleteInput, SelectInput } from 'react-admin';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';

import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import DateField from '../common/fields/DateField';
import IconButton from '../common/IconButton';
import SplitDateButtons from '../common/SplitDateButtons';
import StarlinkUsageReportGraph from './StarlinkUsageReportGraph';

import { API_URL } from '../../services/settings';

const customerOptionText = choice => choice.name ? choice.name : '';

const groupByChoices = [
	{ id: 'site', name: 'Site' },
];

const StarlinkUsageReportListActions = (props) => {
	const params = new URLSearchParams();
	params.append('format', 'xls');
	for (const key in props.filterValues) {
		params.append(key, props.filterValues[key]);
	}

	return (
		<ListActions {...props}>
			<IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/starlink_usages/report?${params.toString()}`}>Export</IconButton>
		</ListActions>
	);
};

const StarlinkUsageFilter = (props) => (
	<Filter {...props}>
		<ReferenceInput label="Site" source="site_id" reference="sites" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
			<AutocompleteInput optionText={customerOptionText} />
		</ReferenceInput>
		<SelectInput source="group_by" choices={groupByChoices} alwaysOn />
		<DateInput source="start_date" alwaysOn />
		<DateInput source="end_date" alwaysOn />
		<SplitDateButtons source="date" alwaysOn />
	</Filter>
);

const StarlinkUsageReportRow = ({ data, ...props }) => {
	const firstRecord = data[0];

	if(!firstRecord) {
		return null;
	}

	const records = Object.values(data).slice(0, props.total);

	return (
		<>
			<Typography variant="h6" align="right" style={{ padding: '10px' }}>Total: {firstRecord.total_usage} GB </Typography>
			<StarlinkUsageReportGraph data={records} groupBy={props.filterValues.group_by || 'site'} />
			<Datagrid {...props} data={data}>
				{'site' in firstRecord && <TextField source="site" />}
				{'date' in firstRecord && <DateField source="date" /> }
				<TextField source="usage" label="GB" />
			</Datagrid>
		</>
	);
};

const StarlinkUsageReportPagination = (props) => (
	<Pagination {...props} rowsPerPageOptions={[props.total]} perPage={props.total} />
);

const StarlinkUsageReportList = ({ staticContext, ...props }) => (
	<List {...props}>
		<StarlinkUsageReportRow />
	</List>
);

StarlinkUsageReportList.defaultProps = {
	title: "Starlink Usage reports",
	resource: "starlink_usages/report",
	basePath: "starlink_usage/report",
	hasList: true,
	hasShow: false,
	hasCreate: false,
	hasEdit: false,
	filters: <StarlinkUsageFilter />,
	filterDefaultValues: { start_date: moment().startOf('month').format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD') },
	bulkActionButtons: false,
	actions: <StarlinkUsageReportListActions />,
	exporter: false,
	pagination: <StarlinkUsageReportPagination />
};

export default StarlinkUsageReportList;
