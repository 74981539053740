import React from 'react';
import { TextInput } from 'react-admin';

import SiteRow from './SiteRow';
import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import IconButton from '../common/IconButton';

import GetAppIcon from '@material-ui/icons/GetApp';

import { API_URL } from '../../services/settings';


const SiteListActions = (props) => (
    <ListActions {...props}>
        <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/sites/export.xls`}>Export</IconButton>
    </ListActions>
);

const SiteFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const SiteList = props => (
    <List {...props} filters={<SiteFilter />}  actions={<SiteListActions />} exporter={false}>
        <SiteRow />
    </List>
);

export default SiteList;