import React from 'react';
import { TextInput, PasswordInput, BooleanInput } from 'react-admin';

import Edit from '../common/Edit';
import SimpleForm from '../common/SimpleForm';


const UserEdit = props => (
	<Edit {...props}>
		<SimpleForm>
			<TextInput source="id" disabled />
			<TextInput source="email" />
			<TextInput source="firstname" />
			<TextInput source="lastname" />
			<PasswordInput source="password" />
			<BooleanInput source="can_manage_users" />
		</SimpleForm>
	</Edit>
);

export default UserEdit;