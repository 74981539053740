import { API_URL } from './services/settings';

const authProvider = {
	login: ({ username, password }) => {
		const request = new Request(`${API_URL}/account/login`, {
			method: 'POST',
			body: JSON.stringify({ login: username, password }),
			headers: new Headers({ 'Content-Type': 'application/json' }),
		});
		return fetch(request)
			.then(response => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText);
				}
				return response.json();
			})
			.then(({ token, success, message, user }) => {
				if (!success) {
					throw new Error(message);
				}

				localStorage.setItem('token', token);
				localStorage.setItem('permissions', user.permissions);
			});
	},
	logout: () => {
		localStorage.removeItem('token');
		return Promise.resolve();
	},
	checkError: (error) => {
		const { status } = error;
		if (status === 401 || status === 403) {
			localStorage.removeItem('token');
			return Promise.reject();
		}
		return Promise.resolve();
	},
	checkAuth: () => {
		return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
	},
	getPermissions: () => {
		let permissions = localStorage.getItem('permissions');
		if (permissions) {
			permissions = permissions.split(',');
		}
		return permissions ? Promise.resolve(permissions) : Promise.resolve([]);
	},
	getIdentity: async () => {
		try {
			const response = await fetch(`${API_URL}/account`, {
				method: 'GET',
				headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` }),
			});
			const user = await response.json();
			const identity = {
				id: user.id,
				fullName: `${user.firstname} ${user.lastname}`
			}
			return Promise.resolve(identity);
		} catch (error) {
			return Promise.reject(error);
		}
	}
};

export default authProvider;