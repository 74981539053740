import React from 'react';
import { AutocompleteInput, useTranslate } from 'react-admin';


const InvoiceTypeInput = ({ classes, record, source, ...props }) => {
	const translate = useTranslate();

	if (!record) {
		return null;
	}

	const choices = [
		{ id: 'credit', name: translate('Credit note') },
		{ id: 'invoice', name: translate('Invoice') }
	];

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

InvoiceTypeInput.defaultProps = {
	source: 'invoice_type',
	label: 'Type'
};

export default InvoiceTypeInput;
