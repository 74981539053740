import { resolveBrowserLocale } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import en from 'ra-language-english';

import fr from './fr';
import ptBr from './ptBr';
import de from './de';
import es from './es';

const translations = { en, fr, 'pt-br': ptBr, de, es };

const LANGUAGES = [
    { locale: 'en', name: 'EN' },
    { locale: 'fr', name: 'FR' },
    { locale: 'de', name: 'DE' },
    { locale: 'es', name: 'ES' },
    { locale: 'pt-br', name: 'PT-BR' }
];

export const i18nProvider = polyglotI18nProvider(
    locale => translations[locale] ? translations[locale] : translations.en,
    resolveBrowserLocale('en'),
    { allowMissing: true }
);

export default i18nProvider;
export { LANGUAGES };