import React from 'react';
import { Login } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	main: {
		background: '#FFF',
		height: 'auto',
		minHeight: 'auto'
	},
	card: {
		padding: theme.spacing(2),
		border: '1px solid rgba(0, 0, 0, 0.23)',
		borderRadius: '20px',
		marginTop: 0
	},
	avatar: {
		display: 'none'
	}
}));

const CustomLogin = (props) => {
	const classes = useStyles();

	return (
		<Login backgroundImage="" classes={classes} {...props} />
	);
};

export default CustomLogin;
