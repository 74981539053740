import React, { useEffect, useState } from 'react';
import { Confirm, useNotify, useRefresh, useTranslate } from 'react-admin';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

import IconButton from '../../common/IconButton';

import { disableSimCard, enableSimCard, getStatusSimCard } from '../../../services/api';


// TODO refactor because we have at least webbing and telna

const EnableDisableButton = ({ record, variant, color }) => {
	const notify = useNotify();
	const refresh = useRefresh();
	const [showDialog, setShowDialog] = useState(false);
	const [status, setStatus] = useState();
	const translate = useTranslate();

	useEffect(() => {
		const getStatus = async () => {
			const response = await getStatusSimCard(record.id);
			setStatus(response.status);
		};

		if(record && (record.iccid.startsWith('89103000') || record.iccid.startsWith('8985235')) ) {
			getStatus();
		}
	}, [record]);

	const handleConfirm = async () => {
		if(!status) {
			return;
		}

		let response;
		if(status === 'ACTIVE') {
			response = await disableSimCard(record.id);
		}
		else {
			response = await enableSimCard(record.id);
		}

		if(response) {
			if(response.id) {
				notify(status === 'ACTIVE' ? translate('SIM suspended') : translate('SIM enabled'));
				setStatus(status === 'ACTIVE' ? 'SUSPENDED' : 'ACTIVE');
			}
			else if(!response.success) {
				notify('Error', 'warning');
			}
		}

		handleClose();
		refresh();
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	if(!record || (!record.iccid.startsWith('89103000') && !record.iccid.startsWith('8985235')) || !status) {
		return null;
	}

	return (
		<>
			<IconButton icon={status === 'ACTIVE' ? <ClearIcon /> : <CheckIcon />} onClick={handleOpen} variant={variant} color={color}>{status === 'ACTIVE' ? translate('Suspend SIM') : translate('Enable SIM')}</IconButton>
			<Confirm
				isOpen={showDialog}
				title={status === 'ACTIVE' ? translate('Suspend SIM') : translate('Enable SIM')}
				content={status === 'ACTIVE' ? translate('Are you sure you want to suspend the SIM %{iccid}?', { iccid: record.iccid}) : translate('Are you sure you want to enable the SIM %{iccid}?', { iccid: record.iccid})}
				onConfirm={handleConfirm}
				onClose={handleClose}
			/>
		</>
	);
};

export default EnableDisableButton;