import LocationOnIcon from '@material-ui/icons/LocationOn';

import SiteList from './SiteList';
import SiteShow from './SiteShow';

export default {
	list: SiteList,
	show: SiteShow,
	icon: LocationOnIcon
};
