import React from 'react';
import { Tab, TextField, ReferenceField } from 'react-admin';

import DateField from '../../common/fields/DateField';
import NumberCurrencyField from '../../common/fields/NumberCurrencyField';


const SDWanServiceShow = (props) => (
    <Tab label="summary" {...props}>
        <TextField source="status" />
        <ReferenceField label="Package" source="package_id" reference="packages" link="show" allowEmpty>
            <TextField source="reference" />
        </ReferenceField>
        <TextField source="reference" />
        <TextField source="customer_reference"/>
        <TextField source="name" />
        <TextField source="type" />
        <TextField source="frequency" />
        <ReferenceField label="Site" source="site_id" reference="sites" allowEmpty>
            <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Device" source="device_id" reference="devices" allowEmpty>
            <TextField source="serial" />
        </ReferenceField>
        <ReferenceField label="Data pool" source="data_pool_id" reference="data_pools" allowEmpty>
            <TextField source="name" />
        </ReferenceField>
        <TextField source="data_pool_reference" />
        <NumberCurrencyField source="price" />
        <DateField source="start_date" />
        <DateField source="end_date" />
        <DateField source="early_end_date" label="Commitment date" />
        <TextField source="notes" options={{ multiline: true }} rows="4" />
    </Tab>
);

export default SDWanServiceShow;
