import React from 'react';
import { NumberField, TextField } from 'react-admin';
import { withStyles } from '@material-ui/core';

import Datagrid from '../common/Datagrid';
import HumanDateField from '../common/HumanDateField';
import LinkFieldButton from '../common/LinkFieldButton';
import WanField from '../wans/WanField';
import OnlineStatusField from './fields/OnlineStatusField';
import DeviceActionsButtons from './DeviceActionsButtons';


const styles = {
	table: {
		'& .column-wans': {
			maxWidth: '120px'
		}
	}
};

// const DeviceDrawer = (props) => (
// 	<SimpleShowLayout {...props}>
// 		<TextField source="id" />
// 		<TextField source="name" />
// 		<ReferenceField label="Site" source="site.id" reference="sites" link="show" allowEmpty>
// 			<TextField source="name" />
// 		</ReferenceField>
// 		<TextField source="address" />
// 	</SimpleShowLayout>
// );

const DeviceRow = ({ children, classes, ...props }) => (
	<Datagrid className={classes.table} {...props} drawerActions={<DeviceActionsButtons />}>
		<LinkFieldButton label="Serial" sortBy="serial">
			<TextField source="serial" />
		</LinkFieldButton>
		<LinkFieldButton label="Name" sortBy="name">
			<TextField source="name" />
		</LinkFieldButton>
		<LinkFieldButton label="Site" source="site" sortBy="site.name" basePath="/sites">
			<TextField source="name" />
		</LinkFieldButton>
		<OnlineStatusField label="Status" />
		<HumanDateField source="last_online" />
		<NumberField source="current_month_usage"  label="Current Month Usage (GB)" />
		<WanField source="wans" sortable={false} />
		{children}
	</Datagrid>
);

DeviceRow.defaultProps = {
	basePath: '/devices'
};

export default withStyles(styles)(DeviceRow);