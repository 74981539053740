import React from 'react';
import { TabbedShowLayout, Tab, TextField, ReferenceField } from 'react-admin';
import { Helmet } from 'react-helmet';

import Show, { ShowActions } from '../common/Show';

const LineTitle = ({ record }) => (
    <>
        <Helmet>
            <title>{`KB - Line[${record.id}]`}</title>
        </Helmet>
        <span>{`Line #${record.id}`}</span>
    </>
);

const LineActions = (props) => (
	<ShowActions {...props}>
	</ShowActions>
);

const LineShow = (props) => (
    <Show title={<LineTitle />} actions={<LineActions />} {...props}>
        <TabbedShowLayout>
            <Tab label="summary">
                <ReferenceField label="Device" source="device" reference="devices" allowEmpty>
                    <TextField source="serial" />
                </ReferenceField>
                <ReferenceField label="Site" source="site" reference="sites" allowEmpty>
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="provider_name" label="Provider" />
                <TextField label="Address" source="address" />
                <TextField label="Subscription Number" source="subscription_id" />
                <TextField label="Status" source="status" />
                <TextField label="Type" source="line_type" />
                <TextField label="Notes" source="notes" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default LineShow;
