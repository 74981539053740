import React from 'react';
import {
	TextField,
	SimpleShowLayout
} from 'react-admin';

import Datagrid from '../common/Datagrid';
import DateField from '../common/DateField';
import LinkFieldButton from '../common/LinkFieldButton';
import MapComponent from '../common/MapComponent';


const AddressDrawer = (props) => (
	<SimpleShowLayout {...props}>
		<TextField source="id" />
		<TextField source="name" />
		<TextField source="street" />
		<MapComponent height="300px" />
	</SimpleShowLayout>
);

const AddressRow = ({ children, ...props }) => (
	<Datagrid drawer={<AddressDrawer {...props} />} {...props}>
		<LinkFieldButton label="Name" sortBy="name">
			<TextField source="name" />
		</LinkFieldButton>
		<TextField source="full_address" />
		<TextField source="vat" />
		<DateField source="updated_at" />
		{children}
	</Datagrid>
);

export default AddressRow;