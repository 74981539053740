import React from 'react';
import { SingleFieldList } from 'react-admin';

import Badge from '../common/Badge';
import DeviceTooltip from './DeviceTooltip';
import { getDeviceColors } from './deviceColors';

const DeviceListField = ({ data, ...props }) => {
	if (Object.keys(data).length <= 0) {
		return null;
	}

	let count_online = 0;
	let count_offline = 0;
	let count_unknown = 0;
	let count_never_online = 0;
	let total = 0;
	Object.values(data).forEach(device => {
		switch (device.online_status) {
			case "online":
				count_online++;
				break;
			case "offline":
				count_offline++;
				break;
			case "never_online":
				count_never_online++;
				break;
			default:
				count_unknown++;
				break;
		}
		total++;
	});

	if (total < 5 && isNaN(data[0])) {
		return (
			<SingleFieldList linkType="show" data={data} {...props}>
				<DeviceTooltip />
			</SingleFieldList>
		);
	}

	return (
		<>
			{count_online > 0 && <Badge badgeContent={count_online} color={getDeviceColors('online')} />}
			{count_never_online > 0 && <Badge badgeContent={count_never_online} color={getDeviceColors('never_online')} />}
			{count_offline > 0 && <Badge badgeContent={count_offline} color={getDeviceColors('offline')} />}
			{count_unknown > 0 && <Badge badgeContent={count_unknown} color={getDeviceColors('unknown')} />}
		</>
	);
};

export default DeviceListField;