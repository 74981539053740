import React, { useState } from 'react';
import { Confirm, useRefresh, useNotify, useTranslate } from 'react-admin';
import ReplayIcon from '@material-ui/icons/Replay';

import IconButton from '../../common/IconButton';

import { rebootDevice } from '../../../services/api';


const RebootDeviceButton = ({ record }) => {
	const refresh = useRefresh();
	const notify = useNotify();
	const [showDialog, setShowDialog] = useState(false);
	const translate = useTranslate();

	const handleConfirm = async () => {
		const response = await rebootDevice(record.id);
		
		if(response.status === 'success') {
			refresh();
			notify(translate('The device is now rebooting'));
		}
		else if(response.reason) {
			notify(response.reason, 'warning');
		}
		else {
			notify(translate('Reboot failed'), 'warning');
		}

		handleClose();
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	if(!record) {
		return null;
	}

	return (
		<>
			<IconButton icon={<ReplayIcon />} onClick={handleOpen}>Reboot</IconButton>
			<Confirm
				isOpen={showDialog}
				title={translate('Reboot device')}
				content={translate('Do you want to reboot the device') + ` ${record.name} (${record.serial})?`}
				onConfirm={handleConfirm}
				onClose={handleClose}
			/>
		</>
	);
};

export default RebootDeviceButton;