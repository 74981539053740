import React from 'react';
import { TextInput } from 'react-admin';
import List from '../common/List';
import Filter from '../common/Filter';
import LocationUpdateRow from './LocationUpdateRow';


const LocationUpdateFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const LocationUpdateList = props => (
    <List {...props} filters={<LocationUpdateFilter/>} sort={{ field: 'timestamp', order: 'DESC' }} exporter={false} perPage={100} title="Network events">
        <LocationUpdateRow />
    </List>
);

export default LocationUpdateList;