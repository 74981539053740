import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
	{ id: 'sim_a', name: 'SIM A' },
    { id: 'sim_b', name: 'SIM B' },
];


const DataPricingProviderField = ({ classes, record, source = "provider", ...props }) => {
	if (!record) {
		return null;
	}

	if (record[source] && !choices.find((provider) => provider.id === record[source])) {
		choices.push({ id: record[source], name: record[source]});
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

export default DataPricingProviderField;
