import React from 'react';
import { TextInput, NumberInput, FormDataConsumer } from 'react-admin';
import Box from '@material-ui/core/Box';

import Edit from '../common/Edit';
import SimpleForm from '../common/SimpleForm';

const ServiceEdit = props => (
    <Edit {...props}>
        <SimpleForm redirect="show">
            <TextInput source="type" disabled />
            <TextInput source="reference" disabled />
            <FormDataConsumer>
                {({ formData, ...rest }) => (formData.type === 'STARLINK' || formData.type === 'DATA') &&
                    <>
                        <Box>
                            <NumberInput source="data_limit" label="Data Limit Alert (GB)" {...rest} />
                        </Box>
                        <Box>
                            <TextInput source="data_limit_contacts" options={{ multiline: true }} rows="4" placeholder="john.doe@acme.com; jane.doe@acme.com" {...rest} />
                        </Box>
                    </>
                }
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
);

export default ServiceEdit;
