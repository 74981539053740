import React from 'react';
import { TextField, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    container: {
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    circle: {
        display: 'inline-block',
        width: '7px',
        height: '7px',
        borderRadius: '12px',
        backgroundColor: (props) => props.backgroundColor ? props.backgroundColor : '#B3B3B3',
        marginRight: '5px'
    }
});

const StatusField = ({ record, source, ...props }) => {
    const translate = useTranslate();
    const statuses = {
        'DRAFT': translate('DRAFT'),
        'PROVISIONING': translate('PROVISIONING'),
        'DELIVERED': translate('DELIVERED'),
        'ENDING': translate('ENDING'),
        'ENDED': translate('ENDED'),
        'IDLE': translate('IDLE'),
        'SHIPPED': translate('SHIPPED'),
    };

    let backgroundColor;
    const status = record ? record[source] : null;

    switch (status) {
        case 'DELIVERED':
        case 'SHIPPED':
            backgroundColor = '#80F563';
            break;
        case 'PROVISIONING':
        case 'ENDING':
            backgroundColor = '#FFB735';
            break;
        case 'ENDED':
            backgroundColor = '#FF8686';
            break;
        default:
            backgroundColor = '#B3B3B3';
            break;
    }
    const classes = useStyles({ backgroundColor });

    if (!record) {
        return null;
    }

    return (
        <span className={classes.container}>
            <span className={classes.circle} />
            <TextField source={status} record={statuses} {...props} />
        </span>
    );
};

StatusField.defaultProps = {
    source: 'status',
    label: 'Status',
    addLabel: true
};

export default StatusField;
