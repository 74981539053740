import React from 'react';
import { TextInput, Toolbar, SaveButton } from 'react-admin';

import Edit from '../common/Edit';
import SimpleForm from '../common/SimpleForm';
import SimPoolDeleteButton from './SimPoolDeleteButton';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
	},
});

const CustomToolbar = (props) => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton />
        <SimPoolDeleteButton />
    </Toolbar>
);

const SimPoolEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar />}>
            <TextInput source="id" disabled />
            <TextInput source="name" />
            <TextInput source="customer_notes" label="Notes" options={{ multiline: true }} rows="4" />
        </SimpleForm>
    </Edit>
);

export default SimPoolEdit;
