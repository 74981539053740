import React from 'react';
import { useTranslate } from 'react-admin';
import lightBlue from '@material-ui/core/colors/lightBlue';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import { makeStyles } from '@material-ui/core/styles';

import ChipField from '../../common/fields/ChipField';

const useStyles = makeStyles({
	root: {
		textTransform: 'uppercase'
	}
});

const StatusField = ({ record, source, ...props }) => {
	const classes = useStyles();
	const translate = useTranslate();

	if (!record) {
		return null;
	}

	const statuses = {
		'draft': translate('Draft'),
		'sent': translate('Sent'),
		'view': translate('View'),
		'paid': translate('Paid')
	};

	const status = record[source];
	let color;
	switch (status) {
		case 'draft':
			color = orange[100];
			break;
		case 'sent':
		case 'view':
			color = lightBlue[100];
			break;
		case 'paid':
			color = green[100];
			break;
		default:
			break;
	}

	return (
		<ChipField source={status} record={statuses} color={color} className={classes.root} {...props} />
	);
};

StatusField.defaultProps = {
	source: 'status',
	label: 'Status',
	size: 'small',
	addLabel: true
};

export default StatusField;
