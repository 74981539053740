import React from 'react';
import { 
	FormTab,
	TextInput
} from 'react-admin';

import Create from '../common/Create';
import TabbedForm from '../common/TabbedForm';


const AddressCreate = props => (
	<Create {...props}>
		<TabbedForm redirect="list">
			<FormTab label="summary">
				<TextInput source="name" />
				<TextInput source="street" />
				<TextInput source="street_bis" />
				<TextInput source="number" />
				<TextInput source="box" />
				<TextInput source="zipcode" />
				<TextInput source="city" />
				<TextInput source="country" />
				<TextInput source="vat" />
			</FormTab>
		</TabbedForm>
	</Create>
);

export default AddressCreate;