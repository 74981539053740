import React from 'react';
import { useTranslate } from 'react-admin';

import ChipField from '../../common/fields/ChipField';

const InvoiceTypeField = ({ record, source, ...props }) => {
	const translate = useTranslate();

	if (!record) {
		return null;
	}

	const types = {
		'credit': translate('Credit note'),
		'invoice': translate('Invoice')
	};

	const type = record[source];
	return (
		<ChipField source={type} record={types} {...props} />
	);
};

InvoiceTypeField.defaultProps = {
	source: 'invoice_type',
	size: 'small',
	addLabel: true
};

export default InvoiceTypeField;
