// Material Icons v6.3.3
import React from 'react';

const SimPoolIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24" {...props}>
        <g>
            <rect fill="none" height="24" width="24" />
            <path d="M15.88,10.5l1.62,1.62v3.38l-3,0v-5H15.88z M22,8v10c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2L2.01,6C2.01,4.9,2.9,4,4,4h6l2,2 h8C21.1,6,22,6.9,22,8z M19,11.5L16.5,9H13v8l6,0V11.5z" fill="currentColor" />
        </g>
    </svg>
);

export default SimPoolIcon;