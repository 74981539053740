import React from 'react';
import { Link, useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import Logo from './Logo';

import variables from '../../style/variables';

const useStyles = makeStyles({
    gridItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh'
    },
    logo: {
        color: variables.colors.darkGreen
    },
    title: {
        color: variables.colors.darkGreen,
        textAlign: 'center'
    },
    background: {
        backgroundColor: '#EDF5F4',
        backgroundImage: 'url("/img/login.svg")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'contain',
        border: 'inset 30px transparent',
        boxSizing: 'border-box',
        minHeight: '100vh',
        '@media (max-width: 959px)': {
            display: 'none'
        }
    }
});

const LoginLayout = ({ children }) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Grid container>
            <Grid item xs={12} md={6} className={classes.gridItem}>
                <Link to="/login" className={classes.logo}><Logo width={160} /></Link>
                <h1 className={classes.title}>{translate("Welcome to the Venn portal")}</h1>
                {children}
            </Grid>
            <Grid item xs={false} md={6} className={classes.background} />
        </Grid>
    );
};

export default LoginLayout;
