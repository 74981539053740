import React from 'react';
import { useTranslate } from 'react-admin';
import GetAppIcon from '@material-ui/icons/GetApp';

import IconButton from '../../common/IconButton';

import { API_URL } from '../../../services/settings';

const ExportPDFButton = ({ record }) => {
    const translate = useTranslate();

    return (
        <IconButton icon={<GetAppIcon />} component="a" target="__blank" href={`${API_URL}/invoices/${record?.id}/invoice${record?.number ? `_${record.number}` : ''}.pdf?auth_token=${localStorage.getItem('token')}`}>{translate('ra.action.export')}</IconButton>
    );
};

export default ExportPDFButton;