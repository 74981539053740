import React, { useState, useEffect } from 'react';
import { Show as ReactAdminShow, ShowView as ReactAdminShowView, ListButton, EditButton, useTranslate } from 'react-admin';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import inflection from 'inflection';

import CardActions from '../common/CardActions';
import Breadcrumb from '../common/Breadcrumb';

const styles = {
	container: {
		display: 'flex',
		alignItems: 'center'
	}
};

const ShowActions = withStyles(styles)(({ basePath, className, data, hasList, hasEdit, children, resource, breadcrumb, classes }) => {
	const [icon, setIcon] = useState(null);
	const translate = useTranslate();

	const title = translate(`resources.${resource}.name`, {
		smart_count: 2,
		_: inflection.humanize(inflection.pluralize(resource))
	});

	useEffect(() => {
		async function getIcon() {
			try {
				const moduleResource = await import(`../${resource}`);
				if (moduleResource.default && moduleResource.default.icon) {
					setIcon(<moduleResource.default.icon />);
				}
			} catch (e) {
				console.error(e);
			}
		}

		getIcon();
	}, [resource]);

	const breadcrumbPath = [
		{ url: hasList ? basePath : '#', title, isActive: false, icon },
		{
			url: data ? `${basePath}/${data.id}/show` : '',
			title: data ? (data.name ? data.name : data.id) : '',
			isActive: true
		}
	];

	return (
		<CardActions className={className}>
			<Breadcrumb path={breadcrumb || breadcrumbPath} />
			<div className={classes.container}>
				{children}
				{hasList && <ListButton basePath={basePath} />}
				{hasEdit && <EditButton basePath={basePath} record={data} />}
			</div>
		</CardActions>
	);
});

const themeShow = theme => createMuiTheme({
	...theme,
	overrides: {
		MuiFormControl: {
			root: {
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'flex-start',
				alignItems: 'center',
				paddingBottom: '5px',

				'.show-page & > label + div': {
					width: 'auto',
					padding: 0
				}
			}
		},
		MuiFormLabel: {
			root: {
				minWidth: '170px'
			}
		},
		MuiCard: {
			root: {
				boxShadow: 'none',
				borderRadius: '12px'
			}
		}
	}
});

const Show = (props) => (
	<MuiThemeProvider theme={themeShow}>
		<ReactAdminShow {...props} />
	</MuiThemeProvider>
);

Show.defaultProps = {
	actions: <ShowActions />
};

const ShowView = (props) => (
	<MuiThemeProvider theme={themeShow}>
		<ReactAdminShowView {...props} />
	</MuiThemeProvider>
);

export default Show;
export { ShowView, ShowActions, themeShow };