import React from 'react';

import ExportPDFButton from './ExportPDFButton';

const InvoiceActionsButtons = (props) => (
    <>
        <ExportPDFButton {...props} />
    </>
);

export default InvoiceActionsButtons;