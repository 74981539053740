import React from 'react';
import { Link } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = {
	ul: {
		display: 'flex',
		alignItems: 'stretch',
		padding: 0
	},
	li: {
		listStyle: 'none',

		display: 'flex',
		alignItems: 'flex-end',

		'& a, & p': {
			lineHeight: '20px'
		},
		'& a': {
			display: 'flex',
			alignItems: 'flex-end',

			'& svg': {
				paddingRight: '5px'
			}
		},
		
		'&:not(:last-child)::after': {
			content: '"/"',
			padding: '0 5px',
			color: 'rgba(0, 0, 0, 0.54)'
		}
	},
	text: {
		display: 'inline',
		fontSize: '14px'
	}
};

const capitalize = (str) => {
	str = str.toString();
	return str.charAt(0).toUpperCase() + str.slice(1);
};

const Breadcrumb = ({ path, classes }) => (
	<ul className={classes.ul}>
		{path.map((item) => (
			<li className={classes.li} key={`breadcrumb_${item.title}`}>
				{!item.isActive ? (
					<Link to={item.url} className={classes.text}>{item.icon} {capitalize(item.title)}</Link>
				) : (
					<Typography className={classes.text}>{capitalize(item.title)}</Typography>
				)}
			</li>
		))}
	</ul>
);

export default withStyles(styles)(Breadcrumb);