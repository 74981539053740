import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { getLastLocationUpdatesSimCard } from '../../services/api';

const SimCardLastLiveData = ({ record }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await getLastLocationUpdatesSimCard(record.id);
            if (response?.length > 0) {
                setData(response);
            }
        };

        fetchData();
    }, [record.id]);

    if (!data || data.length === 0) {
        return null;
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <Typography variant="h6">Last live data</Typography>
                <Table size="small">
                    <TableBody>
                        {Object.entries(data[0]).map(([key, value]) => (
                            <TableRow key={`location_update_${key}`}>
                                <TableCell>{key}</TableCell>
                                <TableCell>{value?.toString()}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
};

export default SimCardLastLiveData;