import React from 'react';

import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import DataPricingRow from './DataPricingRow';
import DataPricingProviderField from './DataPricingProviderField';
import DataPricingCountryField from './DataPricingCountryField';


const DataPricingActions = (props) => (
    <ListActions {...props}>
        {/* <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/data_pricings/export.xls`}>Export</IconButton> */}
    </ListActions>
);

const DataPricingFilter = (props) => (
    <Filter {...props}>
        <DataPricingProviderField label="Provider" source="provider" alwaysOn />
        <DataPricingCountryField label="Country" source="country_code" alwaysOn />
    </Filter>
);

const DataPricingList = props => (
    <List {...props} filters={<DataPricingFilter/>} actions={<DataPricingActions />} exporter={false} perPage={100}>
        <DataPricingRow />
    </List>
);

export default DataPricingList;