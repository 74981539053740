import React from 'react';
import { TextInput, ReferenceInput, AutocompleteInput, Toolbar, SaveButton, useNotify, useTranslate, required } from 'react-admin';
import { useFormState } from 'react-final-form';
import Card from '@material-ui/core/Card';
import { Title } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { useHistory, useParams } from 'react-router-dom';

import SimpleForm from '../common/SimpleForm';

import { createTicket } from '../../services/api';


const siteOptionText = choice => {
	return choice ? choice.name : '';
};

const CustomToolbar = ({ onSave, ...props }) => {
	const formState = useFormState();
	const notify = useNotify();
	const history = useHistory();
	const translate = useTranslate();

	const handleClick = async () => {
		if (formState.valid) {
			const response = await createTicket(JSON.stringify(formState.values));
			if(response.success) {
				notify(translate('Ticket created'));
				history.push('/')
			}
		}
	};

	return (
		<Toolbar {...props}>
			<SaveButton handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />
		</Toolbar>
	);
};

const TicketCreate = () => {
	const { site_id } = useParams();
	const translate = useTranslate();

	return (
		<Card>
			<Title title={translate('Support')} />
			<SimpleForm toolbar={<CustomToolbar />}>
				<Typography variant="h5">{translate('Create a ticket')}</Typography>
				<ReferenceInput label="Site" source="site_id" reference="sites" sort={{ field: 'name', order: 'ASC' }} validate={required()} defaultValue={parseInt(site_id)}>
					<AutocompleteInput optionText={siteOptionText} />
				</ReferenceInput>
				<TextInput label="Description" source="description" options={{ multiline: true }} rows="4" validate={required()} />
			</SimpleForm>
		</Card>
	)
};

export default TicketCreate;