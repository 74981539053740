import React from 'react';
import { LoginForm, Link, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import LoginLayout from './common/LoginLayout';
import Login from './common/Login';

const useStyles = makeStyles((theme) => ({
	link: {
		display: 'block',
		textAlign: 'center',
		textDecoration: 'underline',
		marginTop: theme.spacing(1)
	},
}));

const LoginPage = (props) => {
	const classes = useStyles();
	const translate = useTranslate();

	return (
		<LoginLayout>
			<Login {...props}>
				<LoginForm />
				<Link to="/forgot-password" className={classes.link}>{translate('Forgot password?')}</Link>
			</Login>
		</LoginLayout>
	);
};

export default LoginPage;
