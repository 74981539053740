import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';

import ListIcon from '@material-ui/icons/List';

const styles = theme => ({
	root: {
		padding: theme.spacing(3),
		boxShadow: 'none',
		borderRadius: '12px'
	},
	content: {
		alignItems: 'center',
		display: 'flex'
	},
	title: {
		color: theme.palette.text.primary,
		fontWeight: 400,
		fontSize: '20px',
		lineHeight: '20px',
		letterSpacing: '0.1px'
	},
	value: {
		marginTop: theme.spacing(),
		color: theme.palette.text.primary,
		fontSize: '36px',
		fontWeight: 400,
		letterSpacing: '0.1px'
	},
	iconWrapper: {
		alignItems: 'center',
		backgroundColor: theme.palette.primary.main,
		borderRadius: '50%',
		display: 'inline-flex',
		height: '4rem',
		justifyContent: 'center',
		marginLeft: 'auto',
		width: '4rem',
		'& svg': {
			color: theme.palette.common.white,
			fontSize: '2rem',
			height: '2rem',
			width: '2rem'
		}
	},
	icon: {
		color: theme.palette.common.white,
		fontSize: '2rem',
		height: '2rem',
		width: '2rem'
	}
});


class IconCard extends Component {
	render() {
		const { title, value, icon, classes } = this.props;

		return (
			<Paper className={classes.root}>
				<div className={classes.content}>
					<div className={classes.details}>
						<Typography className={classes.title} variant="body1">{title}</Typography>
						<Typography className={classes.value} variant="h4">{value}</Typography>
					</div>
					<div className={classes.iconWrapper}>
						{icon ? icon : <ListIcon />}
					</div>
				</div>
			</Paper>
		);
	}
}

export default withStyles(styles)(IconCard);
