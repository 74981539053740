import React from 'react';
import { useRefresh, useNotify, useTranslate } from 'react-admin';
import ReplayIcon from '@material-ui/icons/Replay';

import IconButton from '../../common/IconButton';

import { syncDevice } from '../../../services/api';


const SyncDeviceButton = ({ record }) => {
	const refresh = useRefresh();
	const notify = useNotify();
	const translate = useTranslate();

	const handleClick = async () => {
		const response = await syncDevice(record.id);
		if (response.success) {
			notify(translate('Device synchronized'));
		}
		refresh();
	};

	if(!record) {
		return null;
	}

	return (
		<IconButton icon={<ReplayIcon />} onClick={handleClick}>{translate('Sync')}</IconButton>
	);
};

export default SyncDeviceButton;
