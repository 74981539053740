import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { Search } from 'semantic-ui-react';
import { makeStyles } from '@material-ui/core/styles';

import { search } from '../../services/api';

import 'semantic-ui-css/components/search.css';
import 'semantic-ui-css/components/icon.css';
import 'semantic-ui-css/components/input.css';

const useStyles = makeStyles({
	root: {
		flex: 1,
		marginLeft: '25px',
		marginRight: '25px',
		'& .input > input': {
			border: 'none',
			borderRadius: '4px !important',
			backgroundColor: 'rgba(255, 255, 255, 0.2)',
			caretColor: '#FFF',
			color: '#FFF',
			'&::placeholder': {
				color: 'rgba(255, 255, 255, 0.5)'
			},
			'&:focus': {
				backgroundColor: 'rgba(255, 255, 255, 0.2)',
				color: '#FFF',
				'&::placeholder': {
					color: '#FFF'
				}
			}
		},
		'& .results': {
			boxShadow: 'none !important',
			overflow: 'hidden',
			'& .description': {
				whiteSpace: 'pre'
			},
		},
		'& .search.icon': {
			color: '#FFF',
			'&:after': {
				borderColor: '#FFF transparent transparent !important'
			}
		},
		'@media (max-width: 600px)': {
			marginLeft: '10px',
			marginRight: '10px',
			'& input': {
				width: '70px'
			}
		}
	}
});

const SearchBar = ({ staticContext, ...props }) => {
	const [value, setValue] = useState('');
	const [results, setResults] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const classes = useStyles();
	const translate = useTranslate();

	const handleResultSelect = (e, { result }) => {
		props.history.push(result.url);
		setValue('');
		setResults([]);
	};

	const handleSearchChange = async (e, { value }) => {
		setValue(value);
		setIsLoading(true);
		setResults([])

		const results = await search(value);
		setIsLoading(false);
		setResults(results);
	}

	return (
		<Search
			category
			minCharacters={2}
			loading={isLoading}
			onResultSelect={handleResultSelect}
			onSearchChange={handleSearchChange}
			results={results}
			value={value}
			placeholder={translate('Search') + '...'}
			className={classes.root}
			{...props}
		/>
	)
}

export default withRouter(SearchBar);
