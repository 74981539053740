import React from 'react';
import {
    BooleanInput,
    TextInput
} from 'react-admin';


import AddressRow from './AddressRow';
import List from '../common/List';
import Filter from '../common/Filter';

const AddressFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <BooleanInput label="Archived" source="archived" alwaysOn />
    </Filter>
);

const filterDefaultValues = {
    archived: false
};

const AddressList = props => (
    <List {...props} filters={<AddressFilter />} filterDefaultValues={filterDefaultValues} >
        <AddressRow />
    </List>
);

export default AddressList;