import React from 'react';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';

import ChipField from '../../common/ChipField';

const statuses = {
    'online': 'ONLINE',
    'offline': 'OFFLINE',
    'unknown': 'UNKNOWN',
    'searching': 'SEARCHING'
};

const StateField = ({ record, source, ...props }) => {
    let backgroundColor;
    const status = record ? record[source] : null;

    switch (status) {
        case 'online':
            backgroundColor = green[100];
            break;
        case 'offline':
            backgroundColor = red[100];
            break;
        default:
            backgroundColor = grey[100];
            break;
    }

    if (!record || !status) {
        return null;
    }

    return (
        <ChipField source={status} record={statuses} color={backgroundColor} {...props} />
    );
};

StateField.defaultProps = {
    source: 'state',
    label: 'Status',
    addLabel: true
};

export default StateField;