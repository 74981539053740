import React from 'react';
import { TextInput, BooleanInput } from 'react-admin';
import GetAppIcon from '@material-ui/icons/GetApp';

import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import PackageLineRow from './PackageLineRow';
import IconButton from '../common/IconButton';

import { API_URL } from '../../services/settings';


const PackageLineActions = (props) => (
    <ListActions {...props}>
        <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/package_lines/export.xls`}>Export</IconButton>
    </ListActions>
);

const PackageLineFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <BooleanInput label="Invoiced" source="invoiced" alwaysOn />
    </Filter>
);

const filterDefaultValues = {
    invoiced: false,
}

const PackageLineList = props => (
	<List {...props} filters={<PackageLineFilter/>} actions={<PackageLineActions />} filterDefaultValues={ filterDefaultValues } perPage={50} exporter={false}>
		<PackageLineRow />
	</List>
);

export default PackageLineList;
