import React from 'react';
import { Datagrid, TextInput, TextField, ReferenceField, ReferenceInput, AutocompleteInput, DateInput, downloadCSV, FunctionField } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import Typography from '@material-ui/core/Typography';

import List from '../common/List';
import Filter from '../common/Filter';
import DateField from '../common/DateField';
import LinkFieldButton from '../common/LinkFieldButton';

const siteOptionText = choice => {
    return choice.name ? choice.name : '';
};

const simcardOptionText = choice => {
    return choice.iccid ? choice.iccid : '';
};

const DeviceField = ({ record }) => (
    <Typography variant="body2">{`${record.serial} / ${record.name}`}</Typography>
);

const exporter = async (records, fetchRelatedRecords) => {
    const sites = await fetchRelatedRecords(records, 'site_id', 'sites');
    const customers = await fetchRelatedRecords(Object.values(sites), 'customer_id', 'customers');

    const data = records.map(record => ({
        device: record.device.serial,
        customer: customers[sites[record.site_id].customer_id].name,
        site: sites[record.site_id].name,
        start_date: record.start_date,
        end_date: record.end_date,
        duration: record.duration,
        current_month_duration: record.current_month_duration,
        current_month_ratio: record.current_month_ratio
    }));

    jsonExport(data, {
        headers: ['device', 'customer', 'site', 'start_date', 'end_date', 'duration', 'current_month_duration', 'current_month_ratio']
    }, (err, csv) => {
        downloadCSV(csv, 'records');
    });
};

const UsageFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Simcard" source="sim_id" reference="simcards" alwaysOn>
            <AutocompleteInput optionText={simcardOptionText} />
        </ReferenceInput>
        <DateInput source="start_date" alwaysOn />
        <DateInput source="end_date" alwaysOn />
        <ReferenceInput label="Site" source="site_id" reference="sites" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
            <AutocompleteInput optionText={siteOptionText} />
        </ReferenceInput>
    </Filter>
);

const UsageList = props => (
    <List {...props } filters={<UsageFilter/>} perPage={100} bulkActionButtons={false} exporter={exporter}>
        <Datagrid>
            <ReferenceField label="Site" source="site" reference="sites" link="show" allowEmpty sortable={false}>
                <TextField source="name" />
            </ReferenceField>
            <LinkFieldButton source="device" basePath="devices" sortable={false}>
                <DeviceField />
            </LinkFieldButton>
            <ReferenceField label="Simcard" source="sim" reference="simcards" link="show" allowEmpty sortable={false}>
                <FunctionField label="iccid" source="iccid" render={record => `${record.iccid_formatted}`} />
            </ReferenceField>
            <DateField source="date" />
            <TextField source="total" label="GB" sortable={false} />
        </Datagrid>
    </List>
);

export default UsageList;
