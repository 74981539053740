import React from 'react';
import { TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';

import GetAppIcon from '@material-ui/icons/GetApp';

import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import StarlinkTerminalRow from './StarlinkTerminalRow';

import IconButton from '../common/IconButton';

import { API_URL } from '../../services/settings';

const filterDefaultValues = {
    archived: false
};

const siteOptionText = choice => choice.name ? choice.name : '';

const StarlinkTerminalListActions = (props) => (
    <ListActions {...props}>
        <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/starlink_terminals/export.xls`}>Export</IconButton>
    </ListActions>
);

const StarlinkTerminalFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Site" source="site_id" reference="sites" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
            <AutocompleteInput optionText={siteOptionText} />
        </ReferenceInput>
    </Filter>
);

const StarlinkTerminalList = props => (
    <List {...props} filters={<StarlinkTerminalFilter />} filterDefaultValues={filterDefaultValues} actions={<StarlinkTerminalListActions />} exporter={false}>
        <StarlinkTerminalRow />
    </List>
);

export default StarlinkTerminalList;