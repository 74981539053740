import React, { useState } from 'react';
import { SaveButton as RASaveButton, useRefresh, useNotify, useUnselectAll, ReferenceInput, AutocompleteInput } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import IconButton from '../../common/IconButton';
import SimpleForm from '../../common/SimpleForm';
import Toolbar from '../../common/Toolbar';

import { updateSimCard } from '../../../services/api';


const optionText = choice => choice.name ? choice.name : '';

const SaveButton = ({ onClick, selectedIds, record, ...props }) => {
	const formState = useFormState();
	const form = useForm();
	const notify = useNotify();

	const handleClick = async () => {
		let errorIds = [];
		const data = { pool_id: formState.values.pool_id };
		selectedIds.forEach(async simId => {
			const response = await updateSimCard(simId, JSON.stringify(data));
			if (!response.success) {
				errorIds.push(simId);
			}
		});
		form.reset();
		notify(`${selectedIds.length - errorIds.length} SIM cards moved`, errorIds.length === 0 ? 'info' : 'warning')
		onClick();
	};

	return <RASaveButton {...props} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />;
};

const BulkMoveToPoolButton = ({ selectedIds, record, ...props }) => {
	const refresh = useRefresh();
	const unselectAll = useUnselectAll();
	const [showDialog, setShowDialog] = useState(false);

	const handleSave = () => {
		refresh();
		handleClose();
		unselectAll('simcards');
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	return (
		<>
			<IconButton icon={<SwapHorizIcon />} onClick={handleOpen}>Move to SIM pool</IconButton>
			<Dialog fullWidth open={showDialog} onClose={handleClose} aria-label="Move to SIM pool" disablePortal>
				<DialogTitle>Move to SIM pool</DialogTitle>
				<DialogContent>
					Are you sure to move {selectedIds.length} simcards to the SIM pool?
					<SimpleForm toolbar={<Toolbar onClose={handleClose} onSave={handleSave} saveButton={<SaveButton onClick={handleSave} selectedIds={selectedIds} />} />} record={record}>
						<ReferenceInput label="Pool" source="pool_id" reference="simpools" sort={{ field: "name", order: "ASC" }} allowEmpty>
							<AutocompleteInput optionText={optionText} />
						</ReferenceInput>
					</SimpleForm>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default BulkMoveToPoolButton;