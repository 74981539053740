import React from 'react';
import { TextInput } from 'react-admin';

import Edit from '../common/Edit';
import SimpleForm from '../common/SimpleForm';


const AddressEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled />
            <TextInput source="name" />
            <TextInput source="street" />
            <TextInput source="street_bis" />
            <TextInput source="number" />
            <TextInput source="box" />
            <TextInput source="zipcode" />
            <TextInput source="city" />
            <TextInput source="country" />
            <TextInput source="vat" />
            <TextInput source="email" />
            <TextInput source="phone" />
        </SimpleForm>
    </Edit>
);

export default AddressEdit;