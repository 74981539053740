import React from 'react';

import ChipField from '../../common/ChipField';

import { getDeviceColors } from '../deviceColors';

const STATUSES = {
    'online': 'Online',
	'never_online': 'Never online',
    'offline': 'Offline',
    'unknown': 'Unknown',
    null: 'Unknown',
};

const OnlineStatusField = ({ record, source, ...props }) => {
	const color = getDeviceColors(record[source]);
	const status = record[source];

	return (
		<ChipField source={status} record={STATUSES} color={color} {...props} />
	);
};

OnlineStatusField.defaultProps = {
	source: 'online_status',
	addLabel: true
};

export default OnlineStatusField;