import React from 'react';
import { TextField } from 'react-admin';

import Datagrid from '../common/Datagrid';
import LinkFieldButton from '../common/LinkFieldButton';
import CustomList from '../common/CustomList';
import RecordSplitButton from '../common/RecordSplitButton';
import SimCardRow from '../simcards/SimCardRow';
import BulkMoveToPoolButton from '../simcards/SimCardActionsButtons/BulkMoveToPoolButton';
import SimPoolDeleteButton from './SimPoolDeleteButton';


const SimCardList = ({ hasBulkActions, ...props }) => (
	<CustomList
		{...props}
		title=" "
		hasCreate={false}
		perPage={25}
		bulkActionButtons={<BulkMoveToPoolButton />}
		filter={{ pool_id: props.record.id }}
		basePath="/simcards"
		resource="simcards"
	>
		<SimCardRow exclude={['customer', 'simpool']} />
	</CustomList>
);

// const SimPoolDrawer = props => (
// 	<SimpleShowLayout {...props}>
// 		<TextField source="name" />
// 		<DateField source="created_at" />
// 		<DateField source="updated_at" />
// 	</SimpleShowLayout>
// );

const SimPoolRow = ({ children, ...props }) => (
	<Datagrid expand={<SimCardList {...props} />} {...props}>
		<LinkFieldButton label="Name" sortBy="name">
			<TextField source="name" />
		</LinkFieldButton>
		<TextField source="customer_notes" label="Notes" />
		<TextField source="cards_count" sortable={false} />
		<RecordSplitButton archiveButton={<SimPoolDeleteButton />} />
		{children}
	</Datagrid>
);

export default SimPoolRow;
