import React from 'react';
import { AutocompleteInput, useTranslate } from 'react-admin';


const StatusInput = ({ classes, record, source, ...props }) => {
	const translate = useTranslate();

	if (!record) {
		return null;
	}

	const choices = [
		{ id: 'sent', name: translate('Sent') },
		{ id: 'view', name: translate('View') },
		{ id: 'paid', name: translate('Paid') }
	];

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

StatusInput.defaultProps = {
	source: 'status',
	label: 'Status'
};

export default StatusInput;
