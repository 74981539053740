import RouterIcon from '@material-ui/icons/Router';
import DeviceShow from './DeviceShow';
import DeviceList from './DeviceList';
import DeviceEdit from './DeviceEdit';

export default {
	icon: RouterIcon,
	show: DeviceShow,
	list: DeviceList,
	edit: DeviceEdit
};
