import { API_URL } from './settings';

// Workaround for the first call after login, maybe use Axios later?
const getOptions = () => ({
	headers: new Headers({
		'Authorization': `Bearer ${localStorage.getItem('token')}`
	})
});

export const search = async (terms) => {
	const response = await fetch(`${API_URL}/search/live?q=${terms}`, getOptions());
	const { results } = await response.json();
	return results;
}

export const fetchDashboardStats = async () => {
	const response = await fetch(`${API_URL}/dashboard/stats`, getOptions());
	return await response.json();
}

export const removeFromSimPool = async (poolId, simId) => {
	const response = await fetch(`${API_URL}/simpools/${poolId}/simcards/${simId}`, { ...getOptions(), method: 'DELETE' });
	return await response.json();
}

export const updateSimCard = async (simId, data) => {
	const response = await fetch(`${API_URL}/simcards/${simId}`, { ...getOptions(), method: 'PUT', body: data });
	return await response.json();
}

export const getStatusSimCard = async (simId) => {
	const response = await fetch(`${API_URL}/simcards/${simId}/status`, getOptions());
	return await response.json();
}

export const enableSimCard = async (simId) => {
	const response = await fetch(`${API_URL}/simcards/${simId}/enable`, { ...getOptions(), method: 'POST' });
	return await response.json();
}

export const disableSimCard = async (simId) => {
	const response = await fetch(`${API_URL}/simcards/${simId}/disable`, { ...getOptions(), method: 'POST' });
	return await response.json();
}

export const getLastLocationUpdatesSimCard = async (simId) => {
	const response = await fetch(`${API_URL}/simcards/${simId}/last_location_updates`, getOptions());
	return await response.json();
};

/* Usage */
export const getUsage = async ({ sim_id, device_id, site_id, start_date, end_date }) => {
	const params = new URLSearchParams();
	params.append('sort', 'date');
	params.append('order', 'ASC');

	if (sim_id) {
		params.append('sim_id', sim_id);
	}
	if (device_id) {
		params.append('device_id', device_id);
	}
	if (site_id) {
		params.append('site_id', site_id);
	}
	if (start_date) {
		params.append('start_date', start_date);
	}
	if (end_date) {
		params.append('end_date', end_date);
	}

	const response = await fetch(`${API_URL}/usages?${params.toString()}`, getOptions());
	return await response.json();
}

export const getUsageStats = async ({ sim_id, device_id, site_id, start_date, end_date, freq, source }) => {
	const params = new URLSearchParams();
	params.append('freq', freq || 'daily');

	if (sim_id) {
		params.append('sim_id', sim_id);
	}
	if (device_id) {
		params.append('device_id', device_id);
	}
	if (site_id) {
		params.append('site_id', site_id);
	}
	if (start_date) {
		params.append('start_date', start_date);
	}
	if (end_date) {
		params.append('end_date', end_date);
	}
	if (source) {
		params.append('source', source);
	}

	const response = await fetch(`${API_URL}/stats/usages?${params.toString()}`, getOptions());
	return await response.json();
}

export const getDeviceIcDatas = async (deviceId) => {
	// TODO replace url
	const response = await fetch(`${API_URL}/devices/${deviceId}`, getOptions());
	const data = await response.json();
	return data.ic_datas;
}

export const rebootDevice = async (deviceId) => {
	const response = await fetch(`${API_URL}/devices/${deviceId}/reboot`, { ...getOptions(), method: 'POST' });
	return await response.json();
}

export const getDevicesStats = async () => {
	const response = await fetch(`${API_URL}/stats/devices`, getOptions());
	return await response.json();
}

export const getDeviceBandwidth = async (deviceId) => {
	const response = await fetch(`${API_URL}/devices/${deviceId}/bandwidth?include_details=True`, getOptions());
	return await response.json();
}

export const syncDevice = async (deviceId) => {
	const response = await fetch(`${API_URL}/devices/${deviceId}/sync`, getOptions());
	return await response.json();
};

/* Tickets */
export const createTicket = async (data) => {
	const response = await fetch(`${API_URL}/tickets`, { ...getOptions(), method: 'POST', body: data });
	return await response.json();
};

/* Orders */
export const createOrder = async (data) => {
	const response = await fetch(`${API_URL}/orders`, { ...getOptions(), method: 'POST', body: data });
	return await response.json();
};

export const sendOrder = async (orderId) => {
	const response = await fetch(`${API_URL}/orders/${orderId}/send`, { ...getOptions(), method: 'POST' });
	return await response.json();
};

/* Account */
export const getAccount = async () => {
	const response = await fetch(`${API_URL}/account`, getOptions());
	return await response.json();
};

export const updateAccount = async (data) => {
	const response = await fetch(`${API_URL}/account`, { ...getOptions(), method: 'PUT', body: data });
	return await response.json();
};

export const forgotPassword = async (data) => {
	const response = await fetch(`${API_URL}/account/forget_password`, { method: 'POST', body: data });
	return await response.json();
};

export const resetPassword = async (data) => {
	const response = await fetch(`${API_URL}/account/reset_password`, { method: 'POST', body: data });
	return await response.json();
};

/* Starlink */
export const fetchStarlinkTerminalStats = async (terminalId, data) => {
	const params = new URLSearchParams();
	if (data?.name) {
		params.append('name', data.name);
	}
	if (data?.delta) {
		params.append('delta', data.delta);
	}
	const response = await fetch(`${API_URL}/starlink_terminals/${terminalId}/stats?${params.toString()}`, getOptions());
	return await response.json();
};

export const fetchStarlinkTerminalStatus = async (terminalId, data) => {
	const params = new URLSearchParams();
	if (data?.source) {
		params.append('source', data.source);
	}
	const response = await fetch(`${API_URL}/starlink_terminals/${terminalId}/status?${params.toString()}`, getOptions());
	return await response.json();
};

export const rebootStarlinkTerminal = async (terminalId) => {
	const response = await fetch(`${API_URL}/starlink_terminals/${terminalId}/reboot`, { ...getOptions(), method: 'POST' });
	return await response.json();
};

export const getStarlinkUsageStats = async ({ starlink_service_line_id, start_date, end_date, freq }) => {
	const params = new URLSearchParams();
	params.append('freq', freq || 'daily');

	if (starlink_service_line_id) {
		params.append('starlink_service_line_id', starlink_service_line_id);
	}
	if (start_date) {
		params.append('start_date', start_date);
	}
	if (end_date) {
		params.append('end_date', end_date);
	}

	const response = await fetch(`${API_URL}/starlink_usages/stats?${params.toString()}`, getOptions());
	return await response.json();
};

export const optInStarlinkServiceLine = async (starlinkServiceLineId) => {
	const response = await fetch(`${API_URL}/starlink_service_lines/${starlinkServiceLineId}/optin`, { ...getOptions(), method: 'POST' });
	return await response.json();
};

export const optOutStarlinkServiceLine = async (starlinkServiceLineId) => {
	const response = await fetch(`${API_URL}/starlink_service_lines/${starlinkServiceLineId}/optout`, { ...getOptions(), method: 'POST' });
	return await response.json();
};

/* Impersonate */
export const checkImpersonateToken = async (data) => {
	const response = await fetch(`${API_URL}/account/impersonate/check`, { method: 'POST', body: data });
	return await response.json();
};
