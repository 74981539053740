import React, { useState, useEffect, useRef } from 'react';
import { Link, useTranslate, useGetList } from 'react-admin';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import Map, { Marker } from '../common/Map';
import Tooltip from '../common/map/Tooltip';


const useStyles = makeStyles((theme) => ({
    boxUsage: {
        height: '550px',
        boxSizing: 'border-box'
    },
    tableUsage: {
        overflowY: 'auto',
        padding: '15px',
        paddingRight: 0
    },
    row: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.1),
            borderRadius: '100px'
        }
    },
    cell: {
        border: 'none'
    }
}));

const UsageMarker = ({ data, setCurrentMarker, ...props }) => {
    const [tooltipOpen, setTooltipOpen] = useState(props.tooltipOpen || false);
    const translate = useTranslate();

    useEffect(() => {
        setTooltipOpen(props.tooltipOpen);
    }, [props.tooltipOpen]);

    const toggleTooltip = () => {
		if (tooltipOpen) {
			closeTooltip();
		}
		else {
			setCurrentMarker(data);
		}
	};

    const closeTooltip = () => {
        setTooltipOpen(false);
        setCurrentMarker(null);
    };

    if (!data.lat || !data.lng) {
        return null;
    }

    return (
        <Marker
            position={{ lat: data.lat, lng: data.lng }}
            onClick={toggleTooltip}
            {...props}
        >
            {(tooltipOpen) && (
                <Tooltip onCloseClick={closeTooltip}>
                    <Link to={`/sites/${data.id}/show`}><b>{data.name}</b></Link>
                    <Typography>{translate('Mobile')}: {data.current_month_usage}GB</Typography>
                    <Typography>{translate('Starlink')}: {data.starlink_current_month_usage}GB</Typography>
                </Tooltip>
            )}
        </Marker>
    );
};

const SiteList = () => {
    const [currentSite, setCurrentSite] = useState();
    const [zoom, setZoom] = useState(2);
    const [order, setOrder] = useState('DESC');
    const [orderBy, setOrderBy] = useState('current_month_usage');
    const { data: sites, ids: siteIds, isLoading: isLoadingSites, total: totalSites } = useGetList(
        'sites',
        { page: 1, perPage: 100 },
        { field: orderBy, order }
    );
    const translate = useTranslate();
    const classes = useStyles();
    const mapRef = useRef(null);

    // const handleMouseEnterSite = (site) => {
    //     setCurrentSite(site);
    // };

    // const handleMouseLeaveSite = () => {
    //     setCurrentSite(null);
    // };

    const handleZoomChanged = () => {
        if (mapRef.current.getZoom()) {
            setZoom(mapRef.current.getZoom());
        }
    };

    const handleClickSite = (site) => {
        setCurrentSite(site);
        mapRef.current.panTo(new window.google.maps.LatLng(site.lat, site.lng));
        setZoom(12);
    };

    const handleSort = (field) => {
        const isDesc = orderBy === field && order === 'DESC';
        setOrder(isDesc ? 'ASC' : 'DESC');
        setOrderBy(field);
    };

    useEffect(() => {
        const currentSites = Object.values(sites);
        if (mapRef.current && currentSites?.length > 0) {
            let needUpdate = false;
            const bounds = new window.google.maps.LatLngBounds();
            currentSites.forEach(site => {
                if (site.lat && site.lng) {
                    bounds.extend({ lat: site.lat, lng: site.lng });
                    needUpdate = true;
                }
            });
            if (needUpdate) {
                mapRef.current.fitBounds(bounds);
            }
        }
    }, [mapRef.current, sites]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoadingSites || totalSites <= 0) {
        return null;
    }

    return (
        <Grid item xs={12}>
            <Card>
                <Box padding={3}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h6" gutterBottom>{translate('Current Month Data Usage by Site')}</Typography>
                    </Box>
                    <Grid container>
                        <Grid item md={8} xs={12}>
                            <Box className={classes.boxUsage}>
                                <Map height="550px" ref={mapRef} zoom={zoom} onZoomChanged={handleZoomChanged}>
                                    <MarkerClusterer>
                                        {Object.values(sites).map(site => (
                                            <UsageMarker key={`marker_${site.id}`} tooltipOpen={currentSite?.id === site.id} data={site} setCurrentMarker={setCurrentSite} />
                                        ))}
                                    </MarkerClusterer>
                                </Map>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box className={`${classes.boxUsage} ${classes.tableUsage}`}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{translate('Name')}</TableCell>
                                            <TableCell sortDirection={orderBy === 'current_month_usage' ? order.toLowerCase() : false}>
                                                <TableSortLabel
                                                    active={orderBy === 'current_month_usage'}
                                                    direction={orderBy === 'current_month_usage' ? order.toLowerCase() : 'desc'}
                                                    onClick={() => handleSort('current_month_usage')}
                                                >
                                                    {translate('Mobile')}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell sortDirection={orderBy === 'starlink_current_month_usage' ? order.toLowerCase() : false}>
                                                <TableSortLabel
                                                    active={orderBy === 'starlink_current_month_usage'}
                                                    direction={orderBy === 'starlink_current_month_usage' ? order.toLowerCase() : 'desc'}
                                                    onClick={() => handleSort('starlink_current_month_usage')}
                                                >
                                                    {translate('Starlink')}
                                                </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {siteIds?.map(id => (
                                            <TableRow key={`site_${sites[id].id}`} className={classes.row} /*onMouseEnter={() => handleMouseEnterSite(sites[id])} onMouseLeave={handleMouseLeaveSite}*/ onClick={() => handleClickSite(sites[id])}>
                                                <TableCell className={classes.cell}><Link to={`/sites/${sites[id].id}/show`}>{sites[id].name}</Link></TableCell>
                                                <TableCell className={classes.cell}>{sites[id].current_month_usage ? `${sites[id].current_month_usage}GB` : ''}</TableCell>
                                                <TableCell className={classes.cell}>{sites[id].starlink_current_month_usage ? `${sites[id].starlink_current_month_usage}GB` : ''}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
        </Grid>
    );
};

export default SiteList;