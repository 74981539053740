import React from 'react';
import { TabbedShowLayout, Tab, TextField, ReferenceManyField, Pagination } from 'react-admin';
import { Helmet } from 'react-helmet';

import Show from '../common/Show';
import SimCardRow from '../simcards/SimCardRow';

const ProviderTitle = ({ record }) => (
	<>
		<Helmet>
			<title>{`KB - Provider[${record.name}]`}</title>
		</Helmet>
		<span>{record.name}</span>
	</>
);

const ProviderShow = (props) => (
	<Show title={<ProviderTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="summary">
				<TextField label="Id" source="id" />
				<TextField source="iccid_prefix" />
				<TextField source="name" />
				<TextField source="supplier_name" />
				<TextField source="carrier_name" />
				<TextField source="prefered_apn" />
				<TextField source="alternative_apn" />
				<TextField source="username" />
				<TextField source="password" />
				<TextField source="contry_iso" />
				<TextField source="country" />
				<TextField source="country_code" />
				<TextField source="mcc" />
				<TextField source="description" />
			</Tab>
			<Tab label="SIM Cards">
				<ReferenceManyField {...props} pagination={<Pagination />} reference="simcards" target="provider_id" fullWidth addLabel={false}>
					<SimCardRow exclude={['provider', 'provider_name']} />
				</ReferenceManyField>
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default ProviderShow;
