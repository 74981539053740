import React from 'react';
import { TextInput } from 'react-admin';

import List from '../common/List';
import Filter from '../common/Filter';
import ProviderRow from './ProviderRow';

const ProviderFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const ProviderList = props => (
	<List {...props} filters={<ProviderFilter/>}>
		<ProviderRow />
	</List>
);

export default ProviderList;