import DescriptionIcon from '@material-ui/icons/Description';

import InvoiceList from './InvoiceList';
import InvoiceShow from './InvoiceShow';


export default {
	list: InvoiceList,
	show: InvoiceShow,
	icon: DescriptionIcon
};
