import React from 'react';

import RebootDeviceButton from './RebootDeviceButton';
import SyncDeviceButton from './SyncDeviceButton';

const DeviceActionsButtons = ({ record }) => {
	return (
		<>
			<RebootDeviceButton record={record} />
			<SyncDeviceButton record={record} />
		</>
	);
};

export default DeviceActionsButtons;