import React from 'react';
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles(theme => ({
	root: {
		padding: '4px'
	},
	badge: {
		color: props => props.backgroundColor ? props.backgroundColor === grey[100] ? '#000' : '#FFF' : '',
		backgroundColor: (props) => props.backgroundColor ? (props.backgroundColor === 'grey' ? theme.palette.grey[500] : props.backgroundColor) : '',
		position: 'relative',
		transform: 'none',
		paddingTop: '4px'
	}
}));

const CustomBadge = ({ color, ...props }) => {
	let backgroundColor;
	if(!['default', 'primary', 'secondary', 'error'].includes(color)) {
		backgroundColor = color;
		color = 'default';
	}
	const classes = useStyles({ backgroundColor });
	return <Badge color={color} classes={classes} {...props} />;
};

export default CustomBadge;