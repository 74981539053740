import { createMuiTheme } from '@material-ui/core/styles';
import { fade, lighten } from '@material-ui/core/styles/colorManipulator';

import variables from './variables';

const theme = createMuiTheme({
	palette: {
		primary: {
			main: variables.colors.primary
		},
		text: {
			primary: variables.colors.text
		}
	},
	typography: {
		fontFamily: ['TT Commons', 'Roboto', 'sans-serif'].join(', ')
	},
	sidebar: {
		closedWidth: 75
	}
});

const getTheme = (palette = theme.palette) => ({
	...theme,
	overrides: {
		RaLayout: {
			root: {
				backgroundColor: fade(palette.primary.main, 0.1),
				// Customize map
				'& .map': {
					borderRadius: '8px',
					'& > div': {
						backgroundColor: '#BDD5CF !important'
					}
				},
				// Customize charts
				'& .recharts-responsive-container': {
					'& .recharts-cartesian-grid-horizontal line, &.recharts-cartesian-grid-vertical': {
						stroke: '#E5E5E5'
					},
					'& .recharts-xAxis .recharts-cartesian-axis-line': {
						stroke: '#E5E5E5'
					},
					'& .recharts-cartesian-axis-tick line, & .recharts-yAxis .recharts-cartesian-axis-line': {
						display: 'none'
					},
					'& .recharts-tooltip-cursor': {
						fill: '#E5E5E5'
					},
					'& .recharts-brush-texts': {
						fontSize: '12px'
					}
				},
				'& .list-page .MuiToolbar-root': {
					'@media (max-width: 600px)': {
						justifyContent: 'flex-start',
						alignItems: 'center'
					}
				}
			},
			content: {
				marginTop: '15px'
			}
		},
		RaSidebar: {
			paperAnchorDockedLeft: {
				marginTop: '30px'
			},
			paperAnchorLeft: {
				'@media (max-width: 600px)': {
					'& .MuiCollapse-entered': {
						minHeight: 'auto !important'
					}
				}
			}
		},
		RaMenuItemLink: {
			root: {
				margin: '5px 10px',
				borderRadius: '200px',
				color: variables.colors.text,
				'& svg': {
					color: '#000'
				}
			},
			active: {
				backgroundColor: fade(palette.primary.main, 0.1) + '!important',
				color: palette.primary.main,
				'& svg': {
					color: palette.primary.main
				}
			}
		},
		RaLogout: {
			menuItem: {
				margin: '5px 15px',
				borderRadius: '200px',
				color: '#000',
				'& svg': {
					color: '#000'
				},
				'&:hover': {
					backgroundColor: fade(palette.primary.main, 0.1) + '!important',
					color: palette.primary.main,
					'& svg': {
						color: palette.primary.main
					}
				}
			}
		},
		MuiListItem: {
			button: {
				'&:hover': {
					backgroundColor: fade(palette.primary.main, 0.1) + '!important',
					color: palette.primary.main,
					'& svg': {
						color: palette.primary.main
					}
				},

			}
		},
		DashboardMenuItem: {
			active: {
				backgroundColor: fade(palette.primary.main, 0.1)
			}
		},
		MuiAppBar: {
			root: {
				boxShadow: 'none',
				zIndex: 1300
			}
		},
		MuiDrawer: {
			docked: {
				backgroundColor: 'white',
				minHeight: 'calc(100vh - 48px)',
				boxShadow: 'none',
				marginRight: '19px',
				'& > div': {
					height: 'calc(100% - 30px)',
					display: 'flex',
					flexDirection: 'column',

					'& > .outlined-button': {
						margin: '6px 16px',
						justifyContent: 'flex-start'
					},
					'& .footer-menu': {
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						margin: '5px',
						justifySelf: 'flex-end',
						alignSelf: 'center',
						marginTop: 'auto',

						'& > a': {
							marginBottom: '10px'
						}
					}
				}
			}
		},
		MuiCard: {
			root: {
				boxShadow: 'none',
				borderRadius: '12px'
			}
		},
		MuiPaper: {
			elevation4: {
				boxShadow: '0 1px 15px 1px rgba(0, 0, 0, 0.11)'
			}
		},
		MuiFormControl: {
			root: {
				width: '100%'
			}
		},
		MuiSnackbarContent: {
			message: {
				whiteSpace: 'pre-wrap'
			}
		},
		MuiMenuItem: {
			root: {
				'@media (min-width: 600px)': {
					minHeight: '36px'
				}
			}
		},
		MuiButton: {
			root: {
				'& span': {
					lineHeight: 1,
					paddingTop: '5px',
					paddingBottom: '3px'
				}
			},
			contained: {
				boxShadow: 'none',
				borderRadius: '48px',
				'&:hover': {
					boxShadow: 'none'
				}
			},
			outlined: {
				borderColor: variables.colors.borderButton
			},
			text: {
				'& span': {
					fontSize: '13px',
					lineHeight: '13px'
				}
			}
		},
		MuiButtonGroup: {
			grouped: {
				borderRadius: '10px',
				border: `1px solid ${variables.colors.borderButton}`,
				'& span': {
					paddingTop: '3px'
				}
			}
		},
		MuiTableRow: {
			hover: {
				'&:hover': {
					backgroundColor: `${fade(palette.primary.main, palette.action.hoverOpacity)} !important`
				}
			},
			head: {
				'& th:first-child': {
					borderTopLeftRadius: '12px'
				},
				'& th:last-child': {
					borderTopRightRadius: '12px'
				}
			}
		},
		MuiBackdrop: {
			root: {
				backgroundColor: 'rgba(58, 139, 126, 0.20)'
			}
		},
		RaBulkActionsToolbar: {
			toolbar: {
				backgroundColor: lighten(palette.primary.main, 0.945),
				borderTopLeftRadius: theme.shape.borderRadius,
				borderTopRightRadius: theme.shape.borderRadius
			}
		},
		RaList: {
			main: {
				marginTop: '15px'
			}
		}
	},
	props: {
		MuiInputBase: {
			autoComplete: 'off'
		},
		MuiTableCell: {
			size: 'medium'
		},
		MuiTextField: {
			variant: 'outlined'
		}
	}
});

export default getTheme;
