import RouterIcon from '@material-ui/icons/Router';

import PackageLineList from './PackageLineList';
import PackageLineShow from './PackageLineShow';

export default {
	list: PackageLineList,
	show: PackageLineShow,
	icon: RouterIcon,
	title: 'PackageLines'
};
