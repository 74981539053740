import React from 'react';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';

import ChipField from '../../common/ChipField';

const useStyles = makeStyles({
    root: {
        margin: 0
    }
});

const UnobstructedRatioChipField = ({ record, source, ...props }) => {
    const classes = useStyles();
    let backgroundColor;
    const value = get(record, source);
    const formatPercentage = (v) => (v * 100).toFixed(2);

    if (!record || !value) {
        return null;
    }

    if (value >= 0.75) {
        backgroundColor = green[100];
    }
    else if (value >= 0.5) {
        backgroundColor = orange[100];
    }
    else {
        backgroundColor = red[100];
    }

    return (
        <ChipField
            source="unobstructed_ratio"
            record={{ unobstructed_ratio: value ? `${formatPercentage(value)}%` : null }}
            color={backgroundColor}
            className={classes.root}
            {...props}
        />
    );
};

UnobstructedRatioChipField.defaultProps = {
    label: 'Unobstructed ratio',
    addLabel: true
};

export default UnobstructedRatioChipField;