import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { useForm } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';

import SplitButton from '../common/SplitButton';


const useStyles = makeStyles((theme) => ({
    splitButton: {
        '& button': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
            borderRadius: '4px'
        }
    }
}));

const SplitDateButtons = () => {
    const [currentButton, setCurrentButton] = useState();
    const form = useForm();
    const translate = useTranslate();
    const classes = useStyles();

    const handleClickThisMonth = () => {
        form.change('start_date', moment().startOf('month').format('YYYY-MM-DD'));
        form.change('end_date', moment().format('YYYY-MM-DD'));
        setCurrentButton(<Button onClick={handleClickThisMonth}>{translate('This month')}</Button>);
    };

    const handleClickLastMonth = () => {
        form.change('start_date', moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
        form.change('end_date', moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
        setCurrentButton(<Button onClick={handleClickLastMonth}>{translate('Last month')}</Button>);
    };

    const handleClickLast6Months = () => {
        form.change('start_date', moment().subtract(5, 'months').startOf('month').format('YYYY-MM-DD'));
        form.change('end_date', moment().format('YYYY-MM-DD'));
        setCurrentButton(<Button onClick={handleClickLast6Months}>{translate('Last 6 months')}</Button>);
    };

    const handleClickLast12Months = () => {
        form.change('start_date', moment().subtract(11, 'months').startOf('month').format('YYYY-MM-DD'));
        form.change('end_date', moment().format('YYYY-MM-DD'));
        setCurrentButton(<Button onClick={handleClickLast12Months}>{translate('Last 12 months')}</Button>);
    };

    return (
        <Box mb={1}>
            <SplitButton className={classes.splitButton} closeOnButtonClick currentButton={currentButton}>
                <Button onClick={handleClickThisMonth}>{translate('This month')}</Button>
                <Button onClick={handleClickLastMonth}>{translate('Last month')}</Button>
                <Button onClick={handleClickLast6Months}>{translate('Last 6 months')}</Button>
                <Button onClick={handleClickLast12Months}>{translate('Last 12 months')}</Button>
            </SplitButton>
        </Box>
    );
};

export default SplitDateButtons;
