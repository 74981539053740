import React from 'react';
import { TextField, Link, useTranslate } from 'react-admin';
import { PieChart, Pie, Cell } from 'recharts';

import DateField from '../../../common/fields/DateField';
import LinkFieldButton from '../../../common/LinkFieldButton';
import NumberCurrencyField from '../../../common/fields/NumberCurrencyField';
import ServiceCard from './ServiceCard';
import StatusField from './StatusField';

import variables from '../../../../style/variables';

const DataServiceCard = ({ record }) => {
	const translate = useTranslate();

	let data = [];
	if (record.current_usage > record.data_included) {
		data = [
			{
				name: 'Overconsumption',
				value: Math.abs(record.data_included - record.current_usage),
				color: '#FC6F73'
			},
			{ name: 'Data included', value: record.data_included, color: variables.colors.primary },
		];
	}
	else {
		data = [
			{ name: 'Current usage', value: record.current_usage, color: variables.colors.primary },
			{ name: 'Remaining data', value: record.data_included - record.current_usage, color: '#B3B3B3' }
		];
	}

	return (
		<Link to={`/services/${record.id}/show`}>
			<ServiceCard
				header={<>DATA - {record.data_prepaid ? translate('NEXT') : translate('CURRENT')}</>}
				title={
					<LinkFieldButton record={record} basePath="/services">
						<TextField source="name" />
					</LinkFieldButton>
				}
				content={
					<>
						{translate('Price')}: <NumberCurrencyField source="price" record={record} />{` - `}
						{translate('Overconsumption')}: <NumberCurrencyField source="data_extra_price" record={record} />/GB
					</>
				}
				footer={
					record?.start_date ? (
						<>
							<DateField source="start_date" record={record} /> - {record.end_date ? <DateField source="end_date" record={record} /> : translate('Running')}
							<StatusField record={record} />
						</>
					) : <StatusField record={record} />
				}
				contentRight={
					<>
						<PieChart width={100} height={100}>
							<Pie
								data={data}
								innerRadius={30}
								outerRadius={40}
								fill="#8884d8"
								dataKey="value"
								blendStroke
							>
								{data.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color} />)}
							</Pie>
						</PieChart>
						<span>{record.current_usage}/{record.data_included}GB</span>
					</>
				}
			/>
		</Link>
	);
};

export default DataServiceCard;
