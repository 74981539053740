import SimCard from '@material-ui/icons/SimCard';

import SimCardList from './SimCardList';
import SimCardShow from './SimCardShow';

export default {
	list: SimCardList,
	show: SimCardShow,
	icon: SimCard
};
