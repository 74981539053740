import React from 'react';
import { TabbedShowLayout, Tab, ReferenceField, TextField, FunctionField, ReferenceManyField, Pagination, Query, NotFound } from 'react-admin';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';

import { ShowView, ShowActions } from '../common/Show';
import SimCardLastLiveData from './SimCardLastLiveData';
import UsageGraph from '../common/UsageGraph';
import LocationUpdateRow from '../location_updates/LocationUpdateRow';
import EnableDisableButton from './SimCardActionsButtons/EnableDisableButton';
import MoveToPoolButton from './SimCardActionsButtons/MoveToPoolButton';
import StatusField from './StatusField';

const styles = {
	iframe: {
		width: '100%',
		minHeight: '100vh',
		border: 'none'
	}
};

const SimCardTitle = ({ record }) => (
	<>
		<Helmet>
			<title>{`KB - Simcard[${record.id}]`}</title>
		</Helmet>
		<span>{`Simcard #${record.id}`}</span>
	</>
);

const SimCardActions = (props) => (
	<ShowActions {...props}>
		<EnableDisableButton record={props.data} />
		<MoveToPoolButton record={props.data} />
	</ShowActions>
);

/* Fix for a React Admin bug with nested ReferenceField */
const DeviceReferenceField = ({ translateChoice, ...props}) => (
	<ReferenceField {...props} source="device_id" reference="devices" allowEmpty link="show" basePath="/devices">
		<TextField source="name" />
	</ReferenceField>
);

const Tabs = withStyles(styles)(({ classes, ...props }) => (
	<TabbedShowLayout {...props}>
		<Tab label="summary">
			<TextField source="id" />
			<StatusField label="Status" refresh />
			<ReferenceField label="SimPool" source="pool_id" reference="simpools" link="show" allowEmpty>
				<TextField source="name" />
			</ReferenceField>
			<FunctionField label="iccid" source="iccid" render={record => `${record.iccid_formatted}`} />
			<TextField source="imsi" />
			<TextField source="imsi_profile" />
			<TextField source="provider_name" />
			<ReferenceField label="Device" source="wan" reference="wans" allowEmpty link={false} resource="devices">
				<DeviceReferenceField />
			</ReferenceField>
			<ReferenceField label="Site" source="site" reference="sites" link="show" allowEmpty>
				<TextField source="name" />
			</ReferenceField>
			{/* TODO: uncomment when customer_notes ready */}
			{/* <TextField source="customer_notes" label="Notes" /> */}
			<SimCardLastLiveData />
			<UsageGraph />
		</Tab>
		<Tab label="Network Events" path="location_updates">
			<ReferenceManyField addLabel={false} reference="location_updates" target="simcard_id" pagination={<Pagination />} sort={{ field: 'timestamp', order: 'DESC' }}>
				<LocationUpdateRow />
			</ReferenceManyField>
		</Tab>
	</TabbedShowLayout>
));

const SimCardShow = props => (
	// Workaround to have show page works with iccid
	<Query type="GET_ONE" resource="simcards" payload={{ id: props.id }}>
		{({ data }) => {
			if (!data) {
				return null;
			}
			if (!data.id) {
				return <NotFound />;
			}

			return (
				<ShowView title={<SimCardTitle />} actions={<SimCardActions {...props} />} {...props} data={data}>
					<Tabs />
				</ShowView>
			);
		}}
	</Query>
);

export default SimCardShow;