import RouterIcon from '@material-ui/icons/Router';

import ServiceList from './ServiceList';
import ServiceShow from './ServiceShow';
import ServiceEdit from './ServiceEdit';

export default {
	list: ServiceList,
	show: ServiceShow,
	icon: RouterIcon,
	edit: ServiceEdit,
	title: 'Services'
};
