import React from 'react';
import { TextInput, NumberInput } from 'react-admin';

import Edit from '../common/Edit';
import SimpleForm from '../common/SimpleForm';

const DeviceEdit = props => (
	<Edit {...props}>
		<SimpleForm>
			<TextInput source="id" disabled />
            <NumberInput source="data_limit" label="Data Limit Alert (GB)"  />
            <TextInput source="data_limit_contacts" options={{ multiline: true }} rows="4" placeholder="john.doe@acme.com; jane.doe@acme.com" />
            <TextInput source="customer_notes" label="Notes" options={{ multiline: true }} rows="4" />
		</SimpleForm>
	</Edit>
);

export default DeviceEdit;