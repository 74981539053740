import React from 'react';
import get from 'lodash/get';
import { Marker } from 'react-google-maps';

import Map from './Map';


const CustomMarker = ({ position, ...props}) => {
	if (!position.lat || !position.lng) {
		return null;
	}

	return (
		<Marker
			position={position}
			icon={{
				path: "M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z",
				fillColor: '#3A8B7E',
				fillOpacity: 1,
				strokeWeight: 1,
				strokeColor: '#FFF',
				scale: 2,
			}}
			{...props}
		/>
	);
};

const MapComponent = ({ record = {}, source, ...props }) => {
	let obj = record;
	if (source) {
		obj = get(record, source);
	}
	if (!obj || !obj.lat || !obj.lng) {
		return <p></p>;
	}

	return (
		<Map
			defaultCenter={{ lat: obj.lat, lng: obj.lng }}
			defaultZoom={12}
			{...props}
		>
			<CustomMarker position={{ lat: obj.lat, lng: obj.lng }} />
		</Map>
	);
};

export default MapComponent;
