import React, { useEffect, useState } from 'react';
import { AppBar, UserMenu, Layout, MenuItemLink, Link, useTranslate } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import BuildIcon from '@material-ui/icons/Build';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme, createMuiTheme } from '@material-ui/core/styles';

import SearchBar from './common/SearchBar';
import LocalesMenuButton from './common/LocalesMenuButton';
import Menu from './Menu';

import getTheme from '../style/theme';
import variables from '../style/variables';
import { getAccount } from '../services/api';

const useStyles = makeStyles({
    logo: {
        maxHeight: '40px',
        width: '100%',
        '@media (max-width: 600px)': {
            display: 'flex',
            minWidth: '50px'
        }
    },
    link: {
        marginRight: '15px',
        '@media (max-width: 600px)': {
            marginRight: 0
        }
    },
    title: {
        '@media (max-width: 600px)': {
            display: 'none'
        }
    }
});

const AppUserMenu = props => {
    const translate = useTranslate();

    return (
        <UserMenu {...props}>
            <MenuItemLink
                to="/account"
                primaryText={translate('Account')}
                leftIcon={<SettingsIcon />}
            />
            <MenuItemLink
                to="/tickets/new"
                primaryText={translate('Support')}
                leftIcon={<BuildIcon />}
            />
        </UserMenu>
    );
};

const CustomAppBar = props => {
    const theme = useTheme();
    const classes = useStyles();

    return (
        <AppBar {...props} color="primary" userMenu={<AppUserMenu />}>
            <Link to="/" className={classes.link}>
                <img src={theme.logo || "/img/logo.svg"} alt={props.title} className={classes.logo} />
            </Link>
            <Typography variant="h6" id="react-admin-title" color="inherit" className={classes.title} />
            <SearchBar />
            <LocalesMenuButton />
        </AppBar>
    );
};

const AppLayout = ({ theme, ...props }) => {
    const [account, setAccount] = useState();
    let customTheme = createMuiTheme({
        ...theme,
        palette: {
            primary: { main: account?.customer?.primary_color || variables.colors.primary },
            secondary: { main: account?.customer?.secondary_color || variables.colors.secondary },
        },
        logo: account?.customer?.logo
    });

    customTheme = createMuiTheme({
        ...customTheme,
        ...getTheme(customTheme.palette)
    });

    useEffect(() => {
        const fetchAccount = async () => {
            const response = await getAccount();
            setAccount(response);
        };

        fetchAccount();
    }, []);

    if (!account) {
        return null;
    }

    return (
        <Layout {...props} appBar={CustomAppBar} menu={Menu} theme={customTheme} />
    );
};

export default AppLayout;