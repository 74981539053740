import React from 'react';

const Logo = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5739.7 2364.3" {...props}>
        <g>
            <path d="M2777,1082.9c-50.4-242.1-262-423.7-515-423.7c-290.9,0-526.7,239.5-526.7,534.9s235.8,523.9,526.7,523.9
                c205.3,0,383.2-108.4,469.8-282.8c9.2-18.1,17.3-36.6,24.2-55.7c2.6-7.3-3-14.8-10.6-14.8l-214.3-0.6c-4.9,0-8.9,3.1-10.4,7.7
                c-9.1,26.4-27.4,44.9-48.2,63.4c-55.9,50.8-129.7,71.8-210.4,71.8c-145.6,0-268.1-90.7-305.3-225.9c-2-6.9,3.5-13.8,10.6-13.8
                h806.1c5.7,0,10.4-4.1,11.2-9.6c2.6-20.9,3.7-42.1,3.7-63.6C2788.4,1155.9,2784.5,1118.7,2777,1082.9z M2530.6,1082.9h-545.7
                c-6.7,0-11.8-5.7-11-12.4c0.8-5.9,1.8-12,3-17.7c27-131.3,149.2-192.1,276.7-192.1h8.5c127.5,0,249.7,60.8,276.7,192.1
                c1.2,5.7,2.2,11.8,3,17.7C2542.4,1077.2,2537.3,1082.9,2530.6,1082.9z" fill="currentColor" />
            <path d="M1421.3,680.4l-254.9,762.1c-3.4,10.3-18,10.3-21.3,0L890.2,680.4c-1.5-4.6-5.8-7.7-10.7-7.7H679.2
                c-7.6,0-13.1,7.5-10.7,14.7l331.8,996.3c1.5,4.6,5.8,7.7,10.7,7.7h73.6h142.2h73.6c4.9,0,9.2-3.1,10.7-7.7L1643,687.4
                c2.4-7.3-3-14.7-10.7-14.7H1432C1427.1,672.7,1422.8,675.8,1421.3,680.4z" fill="currentColor" />
            <path d="M3918.5,1017c-14.8-199.4-189.4-372.9-457.8-357.4c-84.5,4.9-163.8,35.5-231.8,81.7V682
                c0-4.5-3.6-8.1-8.1-8.1h-210.5c-4.5,0-8.1,3.6-8.1,8.1v998.7c0,6.2,5,11.2,11.2,11.2h204.2c6.2,0,11.2-5,11.2-11.2v-596.9
                c0-113.4,103.9-205.5,231.8-205.5c128.1,0,231.8,92.1,231.8,205.5v596.9c0,6.2,5,11.2,11.2,11.2h204.4c6.2,0,11.2-5,11.2-11.2
                V1041C3919.5,1033,3919.1,1024.9,3918.5,1017z" fill="currentColor" />
            <path d="M5061.8,1017C5047,817.7,4872.4,644.1,4604,659.6c-84.5,4.9-163.8,35.5-231.8,81.7V682c0-4.5-3.6-8.1-8.1-8.1
                h-210.5c-4.5,0-8.1,3.6-8.1,8.1v998.7c0,6.2,5,11.2,11.2,11.2h204.2c6.2,0,11.2-5,11.2-11.2v-596.9
                c0-113.4,103.9-205.5,231.8-205.5c128.1,0,231.8,92.1,231.8,205.5v596.9c0,6.2,5,11.2,11.2,11.2h204.4c6.2,0,11.2-5,11.2-11.2
                V1041C5062.7,1033,5062.3,1024.9,5061.8,1017z" fill="currentColor" />
        </g>
    </svg>
);

export default Logo;