import React from 'react';
import { compose, withProps } from 'recompose';
import { GoogleMap, Marker as GoogleMapsMarker, withScriptjs, withGoogleMap } from 'react-google-maps';

import { GOOGLE_MAP_API_KEY } from '../../services/settings';
import MAP_STYLE from '../../services/mapStyle';

const Marker = ({ position, ...props}) => {
	if (!position.lat || !position.lng) {
		return null;
	}

	return (
		<GoogleMapsMarker
			position={position}
			icon={{
				path: "M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z",
				fillColor: '#3A8B7E',
				fillOpacity: 1,
				strokeWeight: 1,
				strokeColor: '#FFF',
				scale: 2,
			}}
			{...props}
		/>
	);
};

const CustomGoogleMap = compose(
	withProps(props => ({
		googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div style={{ height: props.height || `400px` }} />,
		mapElement: props.mapElement,
		defaultZoom: props.defaultZoom,
		defaultCenter: props.defaultCenter,
		options: { styles: MAP_STYLE }
	})),
	withScriptjs,
	withGoogleMap
)(({ forwardedRef, ...props}) => (
	<GoogleMap {...props} ref={forwardedRef} />
));

const CustomGoogleMapWithRef = React.forwardRef(({ ...props }, ref) => (
	<CustomGoogleMap {...props} forwardedRef={ref} />
));

CustomGoogleMapWithRef.defaultProps = {
	defaultCenter: {
		lat: 46.64,
		lng: 10.45
	},
	defaultZoom: 5,
	mapElement: <div style={{ height: `100%` }} className="map" />
};

export default CustomGoogleMapWithRef;
export { Marker };