import React, { useState } from 'react';
import { Confirm, useRefresh, useTranslate } from 'react-admin';
import ReplayIcon from '@material-ui/icons/Replay';

import IconButton from '../../common/IconButton';

import { rebootStarlinkTerminal } from '../../../services/api';


const RebootButton = ({ record, variant, color }) => {
	const [showDialog, setShowDialog] = useState(false);
	const refresh = useRefresh();
	const translate = useTranslate();

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	const handleReboot = async () => {
		handleClose();
		await rebootStarlinkTerminal(record.terminal_id);
		refresh();
	};

	if (!record) {
		return null;
	}

	return (
		<>
			<IconButton icon={<ReplayIcon />} variant={variant} color={color} onClick={handleOpen}>{translate('Reboot')}</IconButton>
			<Confirm
				isOpen={showDialog}
				title={`Reboot #${record.id}`}
				content={`Are you sure you want to reboot this item?`}
				onConfirm={handleReboot}
				onClose={handleClose}
			/>
		</>
	);
};

export default RebootButton;