import React, { useState } from 'react';
import { Tab, BooleanField, TextField, ReferenceField, ReferenceManyField, NumberField, Confirm, /*useNotify,*/ useRefresh, useTranslate } from 'react-admin';
import CheckIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import { ShowActions } from '../../common/Show';
import DateField from '../../common/fields/DateField';
import IconButton from '../../common/IconButton';
import StarlinkUsageGraph from '../../starlink_usages/StarlinkUsageGraph';
import StarlinkTerminalRow from '../../starlink_terminals/StarlinkTerminalRow';

import { optInStarlinkServiceLine, optOutStarlinkServiceLine } from '../../../services/api';

const StarlinkOptInOutButton = ({ record }) => {
    const [showDialogOptIn, setShowDialogOptIn] = useState(false);
    const [showDialogOptOut, setShowDialogOptOut] = useState(false);
    // const notify = useNotify();
    const refresh = useRefresh();
    const translate = useTranslate();

    const handleOptIn = async () => {
        const response = await optInStarlinkServiceLine(record.starlink_service_line_id);
        // TODO: add notification when success?
        if (response.success) {
            // notify('');
        }

        handleCloseOptIn();
        refresh();
    };

    const handleOptOut = async () => {
        const response = await optOutStarlinkServiceLine(record.starlink_service_line_id);
        // TODO: add notification when success?
        if (response.success) {
            // notify('');
        }

        handleCloseOptOut();
        refresh();
    };

    const handleOpenOptIn = () => {
        setShowDialogOptIn(true);
    };

    const handleOpenOptOut = () => {
        setShowDialogOptOut(true);
    };

    const handleCloseOptIn = () => {
        setShowDialogOptIn(false);
    };

    const handleCloseOptOut = () => {
        setShowDialogOptOut(false);
    };

    if (![true, false].includes(record?.starlink_service_line?.last_service_plan?.isOptedIntoOverage) || !record?.starlink_service_line_id) {
        return null;
    }

    const isOptedIn = record?.starlink_service_line?.last_service_plan?.isOptedIntoOverage;

    if (isOptedIn) {
        return (
            <>
                <IconButton icon={<CancelIcon />} onClick={handleOpenOptOut}>{translate('Opt out')}</IconButton>
                <Confirm
                    isOpen={showDialogOptOut}
                    title="Opt out"
                    content={`Are you sure to opt out the service ${record.name}?`}
                    onConfirm={handleOptOut}
                    onClose={handleCloseOptOut}
                />
            </>
        );
    }

    return (
        <>
            <IconButton icon={<CheckIcon />} onClick={handleOpenOptIn}>{translate('Opt in')}</IconButton>
            <Confirm
                isOpen={showDialogOptIn}
                title="Opt in"
                content={`Are you sure to opt in the service ${record.name}?`}
                onConfirm={handleOptIn}
                onClose={handleCloseOptIn}
            />
        </>
    );
};

const StarlinkServiceActions = (props) => (
    <ShowActions {...props}>
        {/* <StarlinkOptInOutButton record={props.data} /> */}
    </ShowActions>
);

const StarlinkServiceShow = props => {

    return (
        <Tab label="summary" {...props}>
            <TextField source="status" />
            <ReferenceField label="Package" source="package_id" reference="packages" link="show" allowEmpty>
                <TextField source="reference" />
            </ReferenceField>
            <TextField source="reference" />
            <TextField source="customer_reference" />
            <TextField source="name" />
            <TextField source="type" />
            <TextField source="frequency" />
            <TextField source="product_name" />
            <ReferenceField label="Site" source="site_id" reference="sites" allowEmpty>
                <TextField source="name" />
            </ReferenceField>
            <DateField source="start_date" />
            <DateField source="end_date" />
            <DateField source="early_end_date" label="Commitment date" />
            <TextField source="notes" options={{ multiline: true }} rows="4" />
            <BooleanField source="starlink_service_line.last_service_plan.isOptedIntoOverage" label="StarLink Opted Into Overrage" />
            <NumberField label="Last Month Usage (GB)" source="last_usage" />
            <NumberField label="Current Month Usage (GB)" source="current_usage" />
            <NumberField source="starlink_service_line.last_service_plan.overageLine.usageLimitGB" label="Service Plan Usage Limit(GB)" />
            <TextField source="starlink_service_line.last_service_plan.productId" label="Service Plan Product" />
            <TextField source="starlink_service_line.last_service_plan.isoCurrencyCode" label="Currency" />
            <NumberField source="starlink_service_line.last_service_plan.overageLine.overageAmountGB" label="Overrage(GB)" />
            <NumberField source="starlink_service_line.last_service_plan.overageLine.overagePrice" label="Overrage Price" />
            <NumberField source="starlink_service_line.last_service_plan.overageLine.pricePerGB" label="Overrage Price/GB" />
            <NumberField label="Data Alert (GB)" source="data_limit" />
            <TextField label="Data Alert Contacts" source="data_limit_contacts" />
            {props.starlink_service_line_id &&
                <ReferenceManyField addLabel={false} reference="starlink_terminals" target="starlink_service_line_id" source="starlink_service_line_id">
                    <StarlinkTerminalRow />
                </ReferenceManyField>
            }
            <StarlinkUsageGraph />
        </Tab>)
};

export default StarlinkServiceShow
export { StarlinkServiceActions };
