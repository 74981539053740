import React from 'react';
import {
	NumberField,
	TextField,
	SimpleShowLayout,
	ReferenceField
} from 'react-admin';

import Datagrid from '../common/Datagrid';
import LinkFieldButton from '../common/LinkFieldButton';
import RecordSplitButton from '../common/RecordSplitButton';
import BooleanField from '../common/fields/BooleanField';

const PackageLineDrawer = props => (
	<SimpleShowLayout {...props}>
		<TextField source="id" />
		<ReferenceField label="Package" source="package_id" reference="packages" link="show" allowEmpty>
            <TextField source="reference" />
        </ReferenceField>
		<ReferenceField label="Device Product" source="device_product_id" reference="device_products" link="show" allowEmpty>
            <TextField source="reference" />
        </ReferenceField>
		<NumberField source="qty" />
        <NumberField source="price" />
        <BooleanField label="Invoiced" source="is_invoiced" />
        <TextField source="notes" options={{ multiline: true }} rows="4" />
        <TextField source="serials" options={{ multiline: true }} rows="4" />
	</SimpleShowLayout>
);

const PackageLineRow = props => (
	<Datagrid drawer={<PackageLineDrawer {...props} />} {...props}>
		<TextField source="status" />
		<LinkFieldButton label="Package" source="package" basePath="/packages" sortable={false}>
			<TextField source="reference" />
		</LinkFieldButton>
		<ReferenceField label="Device Product" source="device_product_id" reference="device_products" link="show" allowEmpty>
            <TextField source="reference" />
        </ReferenceField>
		<NumberField source="qty" />
        <NumberField source="price" />
        <BooleanField label="Invoiced" source="is_invoiced" />
        <TextField source="notes" options={{ multiline: true }} rows="4" />
        <TextField source="serials" options={{ multiline: true }} rows="4" />
		<RecordSplitButton />
	</Datagrid>
);

export default PackageLineRow;