export default {
	colors: {
		primary: '#3A8B7E',
		secondary: '#3A8B7E',
		text: '#2F2F2F',
		borderButton: '#DADCE0',
		darkGreen: '#1D4943',
		yellow: '#D6DF74'
	}
};
