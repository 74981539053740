import React from 'react';
import { Tab, TextField, ReferenceField, NumberField } from 'react-admin';

import DateField from '../../common/fields/DateField';
import NumberCurrencyField from '../../common/fields/NumberCurrencyField';
import BooleanField from '../../common/fields/BooleanField';


const DataServiceShow = (props) => (
    <Tab label="summary" {...props}>
        <TextField source="status" />
        <ReferenceField label="Package" source="package_id" reference="packages" link="show" allowEmpty>
            <TextField source="reference" />
        </ReferenceField>
        <TextField source="reference" />
        <TextField source="customer_reference"/>
        <TextField source="name" />
        <TextField source="type" />
        <TextField source="frequency" />
        <ReferenceField label="Site" source="site_id" reference="sites" allowEmpty>
            <TextField source="name" />
        </ReferenceField>
        <NumberCurrencyField source="price" />
        <NumberField source="data_included" />
        <BooleanField source="data_prepaid" />
        <DateField source="start_date" />
        <DateField source="end_date" />
        <DateField source="early_end_date" label="Commitment date" />
        <TextField source="notes" options={{ multiline: true }} rows="4" />
    </Tab>
);

export default DataServiceShow;
