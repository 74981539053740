import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
	icon: {
		fontSize: '20px',
		paddingRight: '5px'
	}
});

const IconButton = ({ icon, size, color, classes, children, ...props }) => {
	const customIcon = React.cloneElement(icon, { className: classes.icon });

	return (
		<Button size={ size || "small"} color={ color || "primary" } {...props}>
			{customIcon}
			<span>{children}</span>
		</Button>
	);
};

export default withStyles(styles)(IconButton);
