import React from 'react';
import { TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';

import List from '../common/List';
import Filter from '../common/Filter';
import ServiceRow from './rows/ServiceRow';
import ServiceTypeField from './ServiceTypeField';

const optionText = choice => choice.reference ? choice.reference : '';

const ServiceFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ServiceTypeField label="Type" source="type" alwaysOn />
        <ReferenceInput label="Package" source="package_id" reference="packages" sort={{ field: 'reference', order: 'ASC' }} allowEmpty alwaysOn>
            <AutocompleteInput optionText={optionText} />
        </ReferenceInput>
    </Filter>
);

const filterDefaultValues = {
    archived: false,
}

const ServiceList = props => (
    <List {...props} filters={<ServiceFilter />} filterDefaultValues={filterDefaultValues} perPage={50} exporter={false}>
        <ServiceRow exclude={['start_date', 'end_date']} />
    </List>
);

export default ServiceList;
