import React from 'react';
import { TextField, SimpleShowLayout, SimpleList, ReferenceArrayField, Link, NumberField, ShowButton, useTranslate } from 'react-admin';
import Box from '@material-ui/core/Box';
import BuildIcon from '@material-ui/icons/Build';
import { useMediaQuery } from '@material-ui/core';

import Datagrid from '../common/Datagrid';
import DateField from '../common/DateField';
import LinkFieldButton from '../common/LinkFieldButton';
import MapComponent from '../common/MapComponent';
import DeviceListField from '../devices/DeviceListField';
import IconButton from '../common/IconButton';

const DevicesField = (props) => {
	if (props.record?.devices?.length <= 4) {
		return (
			<ReferenceArrayField label="Devices" reference="devices" source="devices" sortable={false} {...props}>
				<DeviceListField style={{ display: 'inline-block' }} />
			</ReferenceArrayField>
		);
	}

	return (
		<DeviceListField style={{ display: 'inline-block' }} data={props.record.devices} />
	);
};

const SiteDrawerActions = ({ record }) => {
	const translate = useTranslate();

	return (
		<IconButton icon={<BuildIcon />} variant="outlined" component={Link} to={`/tickets/new/${record ? record.id : ''}`}>{translate('Create ticket')}</IconButton>
	);
};

const SiteDrawer = (props) => (
	<SimpleShowLayout {...props}>
		<TextField source="id" />
		<TextField source="name" />
		<TextField source="description" options={{ multiline: true }} />
		<TextField source="address" />
		<MapComponent height="300px" />
	</SimpleShowLayout>
);

const SiteRow = ({ children, ...props }) => {
	const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
	const translate = useTranslate();

	if (isSmall) {
		return (
			<SimpleList
				{...props}
				resource="sites"
				primaryText={record => (
					<Box display="flex" alignItems="center">
						<LinkFieldButton label="Name" sortBy="name" record={record}>
							<TextField source="name" />
						</LinkFieldButton>
						{/* TODO: check if devices are required on mobile */}
						{/* <DevicesField label="Devices" record={record} style={{ pointerEvents: 'none' }} /> */}
					</Box>
				)}
				secondaryText={record => (
					<>
						<Box>
							<TextField record={record} source="address" />
						</Box>
						<Box>
							{(record?.current_month_usage && record?.current_month_usage > 0) ? (
								<>{translate('Mobile')}: <NumberField label="Mobile Current Month Usage(GB)" record={record} source="current_month_usage" options={{ style: 'unit', unit: 'gigabyte' }} locales="en-US" /> </>
							) : ''}
							{(record?.current_month_usage > 0 && record?.starlink_current_month_usage > 0) ? ' - ' : ''}
							{record?.starlink_current_month_usage && record?.starlink_current_month_usage > 0 ? (
								<>{translate('Starlink')}: <NumberField label="StarLink Current Month Usage(GB)" record={record} source="starlink_current_month_usage" options={{ style: 'unit', unit: 'gigabyte' }} locales="en-US" /></>
							) : ''}
						</Box>
					</>
				)}
				linkType="show"
			/>
		);
	}

	return (
		<Datagrid drawer={<SiteDrawer {...props} />} drawerActions={<SiteDrawerActions />} {...props}>
			<LinkFieldButton label="Name" sortBy="name">
				<TextField source="name" />
			</LinkFieldButton>
			<TextField source="address" />
			<DevicesField label="Devices" />
			<NumberField label="Mobile Current Month Usage(GB)" source="current_month_usage" />
			<NumberField label="StarLink Current Month Usage(GB)" source="starlink_current_month_usage" />
			<DateField source="updated_at" />
			{children}
			<ShowButton />
		</Datagrid>
	);
};

export default SiteRow;