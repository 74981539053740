import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
	{ id: 'COAX', name: 'COAX' },
	{ id: 'FTTH', name: 'FTTH' },
    { id: 'Fiber', name: 'Fiber' },
    { id: 'TEL', name: 'TEL' },
	{ id: 'VSAT', name: 'VSAT' },
	{ id: 'xDSL', name: 'xDSL' },
];

const LineTypeField = ({ classes, record, source = "line_type", ...props }) => {
	if (!record) {
		return null;
	}

	if (record[source] && !choices.find((provider) => provider.id === record[source])) {
		choices.push({ id: record[source], name: record[source]});
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

export default LineTypeField;
