import React from 'react';
import { TextField, ReferenceField, SimpleShowLayout } from 'react-admin';

import Datagrid from '../common/Datagrid';

const LineDrawer = (props) => (
	<SimpleShowLayout {...props}>
		<TextField source="id" />
		<ReferenceField label="Device" source="device" reference="devices" link="show" allowEmpty>
			<TextField source="serial" />
		</ReferenceField>
		<ReferenceField label="Site" source="site" reference="sites" link="show" allowEmpty>
			<TextField source="name" />
		</ReferenceField>
		<TextField source="provider_name" label="Provider" />
		<TextField label="Address" source="address" />
		<TextField label="Subscription" source="subscription_id" />
		<TextField label="Status" source="status" />
		<TextField label="Type" source="line_type" />
	</SimpleShowLayout>
);

const LineRow = ({ children, ...props }) => (
	<Datagrid drawer={<LineDrawer {...props} />} {...props}>
		<TextField source="subscription_id" />
		<ReferenceField label="Site" source="site_id" reference="sites" allowEmpty link="show" sortable={false}>
			<TextField source="name" />
		</ReferenceField>
		<TextField source="provider_name" label="Provider" />
		<TextField source="status"/>
		<TextField source="line_type" />
		<ReferenceField label="Device" source="device_id" reference="devices" allowEmpty link="show" sortable={false}>
			<TextField source="serial" />
		</ReferenceField>
		{children}
	</Datagrid>
);

export default LineRow;