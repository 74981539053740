import React, { useState } from 'react';
import Datagrid from './Datagrid';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Drawer from '../Drawer';

const CustomDatagrid = ({ children, exclude, drawer, drawerActions, ...props }) => {
	const [showDrawer, setShowDrawer] = useState(false);
	const [record, setRecord] = useState(null);
	const [prevRecord, setPrevRecord] = useState(null);

	const rowClick = (id, basePath, newRecord) => {
		setShowDrawer(true);
		setPrevRecord(record);
		setRecord(newRecord);
	};

	const handleClose = () => {
		setShowDrawer(false);
		setRecord(null);
		setPrevRecord(null);
	};

	const handleClickAway = () => {
		if (prevRecord === null || record === null || prevRecord === record) {
			handleClose();
		}
		else {
			setPrevRecord(record);
		}
	};

	return (
		<>
			<Datagrid {...props} rowClick={rowClick}>
				{children.map(child => {
					if (!child)
						return null;

					if (!child.props.label && !child.props.source)
						return child;
					return exclude.includes((child.props.label || child.props.source).toLowerCase()) ? null : child
				})}
			</Datagrid>
			{(drawer && record) && (
				<ClickAwayListener onClickAway={handleClickAway}>
					<Drawer open={showDrawer} onClose={handleClose} record={record} basePath={props.basePath} actions={drawerActions}>
						{drawer}
					</Drawer>
				</ClickAwayListener>
			)}
		</>
	);
};

CustomDatagrid.defaultProps = {
	exclude: []
};

export default CustomDatagrid;