import React from 'react';

import ExportStatsButton from './ExportStatsButton';
import RebootButton from './RebootButton';

const StarlinkTerminalActionsButtons = ({ record }) => (
	<>
		<RebootButton record={record} />
		<ExportStatsButton record={record} />
	</>
);

export default StarlinkTerminalActionsButtons;