import React from 'react';
import { TabbedShowLayout } from 'react-admin';
import { Helmet } from 'react-helmet';

import Show from '../../common/Show';
import DefaultServiceShow from './DefaultServiceShow';
import SDWanServiceShow from './SDWanServiceShow';
import DataServiceShow from './DataServiceShow';
import LineServiceShow from './LineServiceShow';
import StarlinkServiceShow, { StarlinkServiceActions } from './StarLinkServiceShow';

const ServiceTitle = ({ record }) => (
	<>
		<Helmet>
			<title>{`KB - Service[${record.name}]`}</title>
		</Helmet>
		<span>{record.name}</span>
	</>
);

const Content = ({ record, ...props }) => {
	const type = record?.type?.toUpperCase();
	if (type === 'SDWAN') {
		return <SDWanServiceShow record={record} {...props} />;
	}

	if (type === 'SDIP') {
		return <SDWanServiceShow record={record} {...props} />;
	}

	if (type === 'DATA') {
		return <DataServiceShow record={record} {...props} />;
	}

	if (type === 'LINE') {
		return <LineServiceShow record={record} {...props} />;
	}

	if (type === 'STARLINK') {
		return <StarlinkServiceShow record={record} {...props} />;
	}

	return <DefaultServiceShow record={record} {...props} />;
};

const Actions = ({ data, ...props }) => {
	const type = data?.type?.toUpperCase();
	if (type === 'STARLINK') {
		return <StarlinkServiceActions data={data} {...props} />;
	}

	return null;
};

const ServiceShow = (props) => (
	<Show title={<ServiceTitle />} actions={<Actions {...props} />} {...props}>
		<TabbedShowLayout>
			<Content />
		</TabbedShowLayout>
	</Show>
);

export default ServiceShow;
