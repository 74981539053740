import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';

import StarlinkTerminalList from './StarlinkTerminalList';
import StarlinkTerminalShow from './StarlinkTerminalShow';

export default {
	list: StarlinkTerminalList,
	show: StarlinkTerminalShow,
	icon: SettingsInputAntennaIcon,
	title: "Starlink terminals"
};
