import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	tooltip: {
		backgroundColor: '#FFF',
		border: '1px solid rgb(204, 204, 204)',
		padding: '0 5px'
	}
});

const GraphToolTip = props => {
	const classes = useStyles();

	return (
		<div className={classes.tooltip} {...props}>
		</div>
	);
};

export default GraphToolTip;