import React from 'react';
import { TextField, ShowButton } from 'react-admin';

import Datagrid from '../common/Datagrid';
import LinkFieldButton from '../common/LinkFieldButton';
import StatusField from './StatusField';

const PackageRow = props => (
	<Datagrid {...props}>
		<LinkFieldButton label="Package Reference" sortBy="reference">
			<TextField source="reference" />
		</LinkFieldButton>
		<LinkFieldButton label="Site" source="site" basePath="/sites">
			<TextField source="name" />
		</LinkFieldButton>
		<LinkFieldButton label="Customer Reference" sortBy="customer_reference">
			<TextField source="customer_reference" />
		</LinkFieldButton>
		<StatusField />
		<ShowButton />
	</Datagrid>
);

export default PackageRow;