import React from 'react';
import { useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';

import IconButton from '../common/IconButton';


const TicketCreateButton = (props) => {
    const translate = useTranslate();

    return (
        <IconButton component={Link} to="/tickets/new" icon={<ContactSupportIcon />} {...props}>{translate('Create ticket')}</IconButton>
    );
};

TicketCreateButton.defaultProps = {
    variant: 'contained',
    color: 'primary'
};

export default TicketCreateButton;