import React from 'react';
import { TabbedShowLayout, SimpleShowLayout, Tab, TextField } from 'react-admin';
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';

import Show from '../common/Show';
import MapComponent from '../common/MapComponent';
import UsageGraph from '../common/UsageGraph';


const AddressTitle = ({ record }) => (
	<>
		<Helmet>
			<title>{`KB - Address[${record.name}]`}</title>
		</Helmet>
		<span>{record.name}</span>
	</>
);

const Content = (props) => (
	<Grid container>
		<Grid item md={6}>
			<SimpleShowLayout {...props}>
				<TextField source="id" />
				<TextField source="name" />
				<TextField source="street" />
				<TextField source="street_bis" />
				<TextField source="number" />
				<TextField source="box" />
				<TextField source="zipcode" />
				<TextField source="city" />
				<TextField source="country" />
				<TextField source="vat" />
				<TextField source="email" />
				<TextField source="phone" />
			</SimpleShowLayout>
		</Grid>
		<Grid item md={6}>
			<MapComponent {...props} />
		</Grid>
	</Grid>
);

const AddressShow = props => (
	<Show title={<AddressTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="summary">
				<Content />
				<UsageGraph />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default AddressShow;