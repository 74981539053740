import React from 'react';
import { TabbedShowLayout, Tab, useTranslate } from 'react-admin';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';

import Show, { ShowActions } from '../common/Show';
import InvoiceActionsButtons from './InvoiceActionsButtons';

import { API_URL } from '../../services/settings';

const useStyles = makeStyles({
    iframe: {
        width: '100%',
        minHeight: '100vh',
        border: 'none'
    }
});

const InvoiceTitle = ({ record }) => {
    const translate = useTranslate();
    const title = translate('resources.invoices.name', { smart_count: 1, _: 'Invoice' });

    return (
        <>
            <Helmet>
                <title>{`KB - ${title}[${record.number}]`}</title>
            </Helmet>
            <span>{record.number}</span>
        </>
    );
};

const InvoiceActions = (props) => (
    <ShowActions {...props}>
        <InvoiceActionsButtons record={props?.data} />
    </ShowActions>
);

const InvoicePDF = ({ record }) => {
    const classes = useStyles();

    return (
        <iframe
            className={classes.iframe}
            title="PDF"
            src={`${API_URL}/invoices/${record?.id}/invoice${record?.number ? `_${record.number}` : ''}.pdf?auth_token=${localStorage.getItem('token')}`}
        />
    );
};

const InvoiceShow = (props) => (
    <Show title={<InvoiceTitle />} actions={<InvoiceActions />} {...props}>
        <TabbedShowLayout>
            <Tab label="PDF">
                <InvoicePDF />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default InvoiceShow;
