import React, { useState, useEffect } from 'react';
import { Create as ReactAdminCreate, ListButton, useTranslate } from 'react-admin';

import CardActions from '../common/CardActions';
import Breadcrumb from '../common/Breadcrumb';


const CreateActions = ({ basePath, className, hasList, children, data, resource, breadcrumb }) => {
	const [icon, setIcon] = useState(null);
	const translate = useTranslate();

	useEffect(() => {
		async function getIcon() {
			try {
				if(resource) {
					const moduleResource = await import(`../${resource}`);
					if (moduleResource.default && moduleResource.default.icon) {
						setIcon(<moduleResource.default.icon />);
					}
				}
			} catch (e) {
				console.error(e);
			}
		}

		getIcon();
	}, [resource]);

	const breadcrumbPath = [
		{ url: basePath, title: resource, isActive: false, icon },
		{
			url: data ? `${basePath}/${data.id}/create` : '',
			title: translate('ra.action.create'),
			isActive: true
		}
	];

	return(
		<CardActions className={className}>
			<Breadcrumb path={breadcrumb || breadcrumbPath} />
			<div>
				{children}
				{hasList && <ListButton basePath={basePath} />}
			</div>
		</CardActions>
	);
};


const Create = ReactAdminCreate;

Create.defaultProps = {
	actions: <CreateActions />
};

export default Create;
export { CreateActions };