import esMessages from '@blackbox-vision/ra-language-spanish';

const es = {
    ...esMessages,
    resources: {
        sites: {
            name: 'Sitio |||| Sitios',
            fields: {
                name: 'Nombre',
                address: 'Dirección',
                updated_at: 'Actualizado El',
            }
        },
        simpools: {
            name: 'Grupo de SIM |||| Grupos de SIM',
            fields: {
                name: 'Nombre',
                invoicing: 'Facturación',
                updated_at: 'Actualizado El',
                created_at: 'Creado El',
                description: 'Descripción',
                cards_count: 'Número De Tarjetas',
            }
        },
        simcards: {
            name: 'Tarjeta SIM |||| Tarjetas SIM',
            fields: {
                iccid: 'ICCID',
                provider_name: 'Nombre Del Proveedor',
                active: 'Activo'
            }
        },
        devices: {
            name: 'Dispositivo |||| Dispositivos',
            fields: {
                serial: 'Serial',
                site: 'Sitio',
                last_online: 'Última Conexión',
                wans: 'WANs',
                name: 'Nombre',
                ip_address: 'Dirección IP',
                updated_at: 'Actualizado El',
                online_status: 'Estado En Línea',
                address: 'Dirección',
                data_limit_contacts: 'Contactos De Límite De Datos',
                created_at: 'Creado El'
            }
        },
        lines: {
            name: 'Línea |||| Líneas',
            fields: {
                subscription_id: 'ID De Suscripción',
                provider_name: 'Nombre Del Proveedor',
                status: 'Estado',
                line_type: 'Tipo De Línea'
            }
        },
        orders: {
            name: 'Orden |||| Órdenes',
            fields: {
                reference: 'Referencia',
                po_reference: 'Referencia PO',
                customer_name: 'Nombre Del Cliente',
                data_region: 'Región De Datos',
                number: 'Número',
            }
        },
        invoices: {
            name: 'Factura |||| Facturas',
            fields: {
                invoice_type: 'Tipo De Factura',
                invoiced_at: 'Facturado El',
                total_untaxed: 'Total Sin Impuestos',
                start_date: 'Fecha De Inicio',
                end_date: 'Fecha De Finalización',
            }
        },
        'usages/report': {
            name: 'Informe de uso |||| Informes de uso',
            fields: {
                site: 'Sitio',
                pool: 'Pool',
                device: 'Dispositivo',
                iccid: 'ICCID',
                start_date: 'Fecha De Inicio',
                end_date: 'Fecha De Finalización',
                group_by: 'Agrupar Por',
            },
        },
        data_pricings: {
            name: 'Precios de datos |||| Precios de datos',
            fields: {
                provider: 'Proveedor',
                country_code: 'Código Del País',
                imsi: 'IMSI',
            },
        },
        location_updates: {
            // name: ' |||| ',
            fields: {
                timestamp: 'Marca Temporal',
                iccid: 'ICCID',
                type: 'Tipo',
                mcc: 'MCC',
                mnc: 'MNC',
                network_name: 'Nombre De La Red',
                imsi: 'IMSI',
                imsi_id: 'ID De IMSI'
            },
        },
        starlink_terminals: {
            name: 'Terminal Starlink |||| Terminales Starlink',
            fields: {
                terminal_id: 'ID Del Terminal',
                kit_serial_number: 'Número De Serie Del KIT',
                dish_serial_number: 'Número De Serie Del Plato',
                alert_install_pending: 'Alerta: Instalación Pendiente',
                alert_is_heating: 'Alerta: Calentamiento',
                alert_mast_not_near_vertical: 'Alerta: Mástil No Cerca De Vertical',
                alert_motors_stuck: 'Alerta: Motores Atascados',
                alert_roaming: 'Alerta: Roaming',
                alert_slow_ethernet_speeds: 'Alerta: Velocidades De Ethernet Lentas',
                alert_thermal_shutdown: 'Alerta: Apagado Térmico',
                alert_unexpected_location: 'Alerta: Ubicación Inesperada'
            }
        },
        'starlink_usages/report': {
            name: 'Informe de uso de Starlink |||| Informes de uso de Starlink',
            fields: {
                group_by: 'Agrupar Por',
                start_date: 'Fecha De Inicio',
                end_date: 'Fecha De Finalización',
            }
        },
        packages: {
            name: 'Paquete |||| Paquetes',
            fields: {
                customer_reference: 'Referencia Del Cliente',
                invoice_regroupment_reference: 'Referencia De Reagrupación De Factura',
                start_date: 'Fecha De Inicio',
                end_date: 'Fecha De Finalización'
            }
        },
        users: {
            name: 'Usuario |||| Usuarios',
            fields: {
                email: 'Correo Electrónico',
                firstname: 'Nombre',
                lastname: 'Apellido',
                password: 'Contraseña',
                is_manager: 'Es Gerente',
                can_order: 'Puede Ordenar',
                can_manage_users: 'Puede Gestionar Usuarios'
            },
        },
    },
    'Search': 'Buscar',
    'Account': 'Cuenta',
    'Support': 'Soporte',
    'summary': 'Resumen',
    'Name': 'Nombre',
    'Devices': 'Dispositivos',
    'Device': 'Dispositivo',
    'Site': 'Sitio',
    'Simcard': 'Tarjeta SIM',
    'ICCID': 'ICCID',
    'Wans': 'WANs',
    'Data Usage monthly (GB)': 'Uso De Datos Mensual (GB)',
    'Data Usage last 30 days (GB)': 'Uso De Datos Últimos 30 Días (GB)',
    'Create ticket': 'Crear Ticket',
    'Move to sim pool': 'Mover A Pool De SIM',
    'Are you sure to move the simcard to the SIM pool?': '¿Estás Seguro De Mover La Tarjeta SIM Al Pool De SIM?',
    'Pool': 'Pool',
    'Current Month Usage (GB)': 'Uso Del Mes Actual (GB)',
    'Sync': 'Sincronizar',
    'Device synchronized': 'Dispositivo Sincronizado',
    'The device is now rebooting': 'El Dispositivo Se Está Reiniciando',
    'Reboot failed': 'Reinicio Fallido',
    'Reboot': 'Reiniciar',
    'Reboot device': 'Reiniciar Dispositivo',
    'Do you want to reboot the device': '¿Deseas Reiniciar El Dispositivo?',
    'Status': 'Estado',
    'Network type': 'Tipo De Red',
    'Online at': 'En Línea El',
    'Product': 'Producto',
    'Product name': 'Nombre Del Producto',
    'Data Limit Alert (GB)': 'Alerta De Límite De Datos (GB)',
    'Create an order': 'Crear Una Orden',
    'Quote Request Only': 'Solo Solicitud De Cotización',
    'Customer name': 'Nombre Del Cliente',
    'Data bundle': 'Paquete De Datos',
    'Bundle': 'Paquete',
    'Overconsumption': 'Sobreconsumo',
    'Fixed IP': 'IP Fija',
    'Expected Start Date': 'Fecha De Inicio Esperada',
    'Duration in months (min 12)': 'Duración En Meses (min 12)',
    'Expected bitrate Down': 'Tasa De Bits Esperada Descendente',
    'Expected bitrate Up': 'Tasa De Bits Esperada Ascendente',
    'Site Address': 'Dirección Del Sitio',
    'Street': 'Calle',
    'Number': 'Número',
    'Zip Code': 'Código Postal',
    'City': 'Ciudad',
    'Country': 'País',
    'Latitude': 'Latitud',
    'Longitude': 'Longitud',
    'Remarks (ordinary building, circus, ...)': 'Observaciones (Edificio Ordinario, Circo, ...)',
    'Contact Person': 'Persona De Contacto',
    'Site Contact Firstname': 'Nombre Del Contacto Del Sitio',
    'Site Contact Lastname': 'Apellido Del Contacto Del Sitio',
    'Site Contact Email': 'Correo Electrónico Del Contacto Del Sitio',
    'Site Contact Phone': 'Teléfono Del Contacto Del Sitio',
    'Shipping': 'Envío',
    'VAT Number': 'Número De IVA',
    'Invoice email address': 'Dirección De Correo Electrónico De Factura',
    'xDSL Line': 'Línea xDSL',
    'Add a Fixed Line': 'Añadir Una Línea Fija',
    'Line Technology': 'Tecnología De Línea',
    'Order created': 'Orden Creada',
    'Send': 'Enviar',
    'Send order': 'Enviar Orden',
    'Error when sending order': 'Error Al Enviar La Orden',
    'Invoicing': 'Facturación',
    'Order sended': 'Orden Enviada',
    'INTRA GOODS 0%': 'BIENES INTRA 0%',
    'INTRA SERVICES 0%': 'SERVICIOS INTRA 0%',
    'EXPORT GOODS 0%': 'EXPORTACIÓN DE BIENES 0%',
    'EXPORT SERVICES 0%': 'EXPORTACIÓN DE SERVICIOS 0%',
    'NA': 'NA',
    'SEAGOING': 'MARCADO',
    'Export data SIM only': 'Exportar Datos Solo SIM',
    'Export data SimPool': 'Exportar datos SimPool',
    'Total': 'Total',
    'Provider': 'Proveedor',
    'Network': 'Red',
    'Price/MB': 'Precio/MB',
    'Usage reports': 'Informes De Uso',
    'Usage Reports': 'Informes De Uso',
    'Data pricing': 'Tarificación De Datos',
    'Map': 'Mapa',
    'Network events': 'Eventos De Red',
    'Create order': 'Crear Orden',
    'Result code': 'Código De Resultado',
    'Access at': 'Acceso En',
    'Forgot password': 'Olvidó La Contraseña',
    'An email has been sent': 'Se Ha Enviado Un Correo Electrónico',
    'Reset password': 'Restablecer Contraseña',
    'Ticket created': 'Ticket Creado',
    'Create a ticket': 'Crear Un Ticket',
    'Forgot password?': '¿Olvidó La Contraseña?',
    'Archived': 'Archivado',
    'Notes': 'Notas',
    'Manager': 'Gerente',
    'Description': 'Descripción',
    'Welcome to the %{name} portal': 'Bienvenido Al Portal De %{name}',
    'This month': 'Este Mes',
    'Last month': 'El Mes Pasado',
    'Last 6 months': 'Últimos 6 Meses',
    'Last 12 months': 'Últimos 12 Meses',
    'ICCID in Pool': 'ICCID En Pool',
    'ICCID in Device': 'ICCID En Dispositivo',
    'Current Month Data Usage by Site': 'Uso De Datos Del Mes Actual Por Sitio',
    'Current Month Data Usage by Simpool': 'Uso De Datos Del Mes Actual Por Simpool',
    'SIM Management': 'Gestión De SIM',
    'Suspend SIM': 'Suspender SIM',
    'Enable SIM': 'Habilitar SIM',
    'Are you sure you want to enable the SIM %{iccid}?': '¿Está Seguro De Que Desea Habilitar La SIM %{iccid}?',
    'Are you sure you want to suspend the SIM %{iccid}?': '¿Está Seguro De Que Desea Suspender La SIM %{iccid}?',
    'SIM suspended': 'SIM Suspendida',
    'SIM enabled': 'SIM Habilitada',
    'Amount due': 'Monto Adeudado',
    'Amount over due': 'Monto Vencido',
    'Daily': 'Diario',
    'Monthly': 'Mensual',
    'Yearly': 'Anual',
    'Mobile': 'Móvil',
    'Usage': 'Uso',
    'Mobile Current Month Usage(GB)': 'Uso Móvil Del Mes Actual (GB)',
    'StarLink Current Month Usage(GB)': 'Uso De StarLink Del Mes Actual (GB)',
    'Serial': 'Serial',
    'Simpools': 'Simpools',
    'Telemetry': 'Telemetría',
    'OOB': 'OOB',
    'Terminal ID': 'ID Del Terminal',
    'Antenna status': 'Estado De La Antena',
    'Downlink speed': 'Velocidad De Bajada',
    'Uplink speed': 'Velocidad De Subida',
    'Latency': 'Latencia',
    'Uptime': 'Tiempo De Actividad',
    'State from OOBM': 'Estado Desde OOBM',
    'Software update state': 'Estado De Actualización De Software',
    'Unobstructed ratio': 'Proporción Despejada',
    'Antenna obstruction': 'Obstrucción De La Antena',
    'Network statistics': 'Estadísticas De Red',
    '15m': '15 Min',
    '1 hr': '1 Hr',
    '24 hrs': '24 Hrs',
    '7 days': '7 Días',
    '30 days': '30 Días',
    'Downlink throughput': 'Rendimiento De Bajada',
    'Uplink throughput': 'Rendimiento De Subida',
    'Ping drop rate': 'Tasa De Pérdida De Ping',
    'Ping drop': 'Pérdida De Ping',
    'Obstruction': 'Obstrucción',
    'Stats export': 'Exportar Estadísticas',
    'Never': 'Nunca',
    'Data Usage': 'Uso De Datos',
    'Settings': 'Configuración',
    'Firstname': 'Nombre',
    'Lastname': 'Apellido',
    'Password': 'Contraseña',
    'KIT Serial': 'Serial Del KIT',
    'Package Reference': 'Referencia Del Paquete',
    'Customer Reference': 'Referencia Del Cliente',
    'Services': 'Servicios',
    'Line': 'Línea',
    'Custom': 'Personalizado',
    'Package ID Reference': 'Referencia Del ID Del Paquete',
    'Draft': 'Borrador',
    'Provisioning': 'Provisionamiento',
    'Deployed': 'Desplegado',
    'Paused': 'Pausado',
    'Ended': 'Finalizado',
    'Shipping Address': 'Dirección De Envío',
    'Shipping Contact': 'Contacto De Envío',
    'Sold Hardware': 'Hardware Vendido',
    'DRAFT': 'BORRADOR',
    'PROVISIONING': 'PROVISIONAMIENTO',
    'DELIVERED': 'ENTREGADO',
    'ENDING': 'FINALIZANDO',
    'ENDED': 'FINALIZADO',
    'IDLE': 'INACTIVO',
    'SHIPPED': 'ENVIADO',
    'Running': 'En Ejecución',
    'LINE': 'LÍNEA',
    'Price': 'Precio',
    'Billing address': 'Dirección De Facturación',
    'Starlink Usage reports': 'Informes De Uso De Starlink',
    '%{length} of %{total} devices': '%{length} De %{total} Dispositivos',
    'Pending packages': 'Paquetes pendientes',
    'Alerts': 'Alertas',
    'Sent': 'Enviado',
    'View': 'Vista',
    'Paid': 'Pagado',
    'Invoice': 'Factura',
    'Credit note': 'Nota de crédito',
    'Fixed lines': 'Líneas fijas',
    'Install pending': 'Instalación pendiente',
    'Is heating': 'Calentamiento',
    'Mast not near vertical': 'Mástil no cerca de la vertical',
    'Motors stuck': 'Motores atascados',
    'Roaming': 'Roaming',
    'Slow ethernet speeds': 'Velocidades ethernet lentas',
    'Thermal shutdown': 'Apagado térmico',
    'Unexpected location': 'Ubicación inesperada',
    'Mobile data usage reports': 'Informes de uso de datos móviles',
    'Export all data': 'Exportar todos los datos'
};

export default es;