import React from 'react';
import {
	NumberField,
	TextField,
	ReferenceField,
	SimpleShowLayout
} from 'react-admin';

import Datagrid from '../../common/Datagrid';
import LinkFieldButton from '../../common/LinkFieldButton';
import DateField from '../../common/fields/DateField';
import NumberCurrencyField from '../../common/fields/NumberCurrencyField';
import BooleanField from '../../common/fields/BooleanField';

const ServiceDrawer = props => (
	<SimpleShowLayout {...props}>
		<TextField source="status" />
		<TextField source="reference" />
		<TextField source="type" />
		<ReferenceField label="Package" source="package_id" reference="packages" link="show" allowEmpty>
			<TextField source="reference" />
		</ReferenceField>
		<TextField source="invoice_reference" />
		<TextField source="name" />
		<NumberCurrencyField source="price" />
		<NumberCurrencyField source="data_included" />
		<NumberCurrencyField source="data_extra_price" />
		<BooleanField source="data_prepaid" />
	</SimpleShowLayout>
);

const ServiceRow = props => (
	<Datagrid drawer={<ServiceDrawer {...props} />} {...props}>
		<LinkFieldButton label="Service ID" sortBy="reference">
			<TextField source="reference" />
		</LinkFieldButton>
		<TextField source="type" />
		<ReferenceField label="Package" source="package_id" reference="packages" link="show" allowEmpty>
			<TextField source="reference" />
		</ReferenceField>
		<DateField label="Start" source="start_date" />
		<DateField label="End" source="end_date" />
		<TextField source="status" />
		<TextField source="name" />
		<LinkFieldButton label="Device" source="device" basePath="/devices" sortable={false}>
			<TextField source="serial" />
		</LinkFieldButton>
		<LinkFieldButton label="Line" source="line" basePath="/lines" sortable={false}>
			<TextField source="subscription_id" />
		</LinkFieldButton>
		<NumberField source="data_included" />
		<NumberCurrencyField source="data_extra_price" />
		<NumberCurrencyField source="price" sortable={false} />
		<NumberField source="last_usage" label="Last Month Usage(GB)" />
		<NumberField source="current_usage" label="Current Month Usage(GB)" />
	</Datagrid>
);

export default ServiceRow;