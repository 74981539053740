import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';

const deviceColors = {
	'online': green,
	'never_online': orange,
	'offline': red,
	'unknown': grey
};

const getDeviceColors = (status, shade = 100) => (
	status ? deviceColors[status][shade] : deviceColors['unknown'][shade]
);

export default deviceColors;
export { getDeviceColors };