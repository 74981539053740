import SimPoolList from './SimPoolList';
import SimPoolEdit from './SimPoolEdit';
import SimPoolCreate from './SimPoolCreate';
import SimPoolShow from './SimPoolShow';
import SimPoolIcon from './SimPoolIcon';

export default {
	list: SimPoolList,
	edit: SimPoolEdit,
	create: SimPoolCreate,
	show: SimPoolShow,
	icon: SimPoolIcon
};
