import React from 'react';
import {
    TextInput,
    BooleanInput
} from 'react-admin';

import SimCardRow from './SimCardRow';
import List from '../common/List';
import Filter from '../common/Filter';

import BulkMoveToPoolButton from './SimCardActionsButtons/BulkMoveToPoolButton';
import { BulkActions } from '../common/List';

const SimCardFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <BooleanInput label="Archived" source="archived" alwaysOn />
    </Filter>
);

const filterDefaultValues = {
    archived: false,
}


const SimCardBulkActions = props => (
    <>
        <BulkMoveToPoolButton {...props} />
        <BulkActions {...props} />
    </>
);

const SimCardList = props => (
    <List {...props} filters={<SimCardFilter />} filterDefaultValues={filterDefaultValues} bulkActionButtons={<SimCardBulkActions />}>
        <SimCardRow />
    </List>
);

export default SimCardList;