import React, { Component } from 'react';
import filesize from 'filesize';

import C3Chart from 'react-c3js';
import 'c3/c3.css';

import { getDeviceBandwidth } from '../../services/api';

export default class DeviceBandwidthGraph extends Component {
  state = {
    data: [],
    series: []
  };

  fetchData = async () => {
    const { record } = this.props;
    try {
      const results = await getDeviceBandwidth(record.id);
      this.setState(results);
    } catch (e) {
      console.error(e);
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.record.id !== this.props.record.id) {
      this.fetchData();
    }
  }

  render() {
    let names = {};

    if (this.state.data && this.state.data.wans) {
      names = this.state.data.wans.filter(wan => wan.id !== 0).reduce((map, wan) => {
        map[wan.id] = wan.name;
        return map;
      }, {});
    }

    let data = {
      columns: this.state.series ? this.state.series : [],
      type: 'line',
      x: 'ts',
      xFormat: '%Y-%m-%dT%H:%M:%S',
      names: names
    };

    let axis = {
      x: {
        type: 'timeseries',
        tick: {
          format: '%Y-%m-%d'
        }
      },
      y: {
        tick: {
          format: function (d) { return filesize(d * 1024 * 1024); }
        }
      }
    }

    return (
      <C3Chart data={data} axis={axis} />
    );
  }
}
