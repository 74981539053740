import React, { useState, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { useHistory, useParams } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import LoginLayout from './common/LoginLayout';

import { checkImpersonateToken } from '../services/api';


const Impersonate = () => {
    const history = useHistory();
    const { token } = useParams();
    const [message, setMessage] = useState('');
    const translate = useTranslate();

    useEffect(() => {
        const fetchData = async () => {
            const formData = new FormData();
            formData.append('token', token);
            const response = await checkImpersonateToken(formData);

            if (response.success && response.token) {
                setMessage(translate('You will be redirected'));
                localStorage.setItem('token', response.token);
                localStorage.setItem('permissions', response.permissions);
                history.push('/');
            } else {
                setMessage(response.message);
            }
        };

        fetchData();
    }, [token, history, translate]);

    return (
		<LoginLayout>
			<Typography align="center">{message}</Typography>
		</LoginLayout>
    );
};

export default Impersonate;
