import React, { useEffect, useState } from 'react';
import { TextInput, PasswordInput, Toolbar, SaveButton, useNotify, required, minLength, useTranslate } from 'react-admin';
import { useFormState } from 'react-final-form';
import Card from '@material-ui/core/Card';
import { Title } from 'react-admin';
import Typography from '@material-ui/core/Typography';

import SimpleForm from './common/SimpleForm';

import { getAccount, updateAccount } from '../services/api';


const CustomToolbar = ({ onSave, ...props }) => {
    const formState = useFormState();
    const notify = useNotify();

    const handleClick = async () => {
        if(formState.valid) {
            const response = await updateAccount(JSON.stringify(formState.values));
            if(response.errors) {
                let message = '';
                for(const error in response.errors) {
                    message += `${error}: ${response.errors[error]}\n`;
                }
                notify(message, "warning");
            }
            else {
                notify("Account updated");
            }
        }
    };

    return (
        <Toolbar {...props}>
            <SaveButton handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />
        </Toolbar>
    );
};

const Account = () => {
    const translate = useTranslate();
    const [account, setAccount] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const response = await getAccount();
            setAccount(response);
        };

        fetchData();
    }, []);


    return (
        <Card>
            <Title title="Account" />
            <SimpleForm record={account} toolbar={<CustomToolbar />}>
                <Typography variant="h5">{translate('Settings')}</Typography>
                <TextInput source="firstname" label="Firstname" validate={required()} />
                <TextInput source="lastname" label="Lastname" validate={required()} />
                <PasswordInput source="password" label="Password" validate={minLength(8)} />
            </SimpleForm>
        </Card>
    );
};

export default Account;