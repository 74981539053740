import React from 'react';
import { DeleteButton, useMutation, useRefresh, useNotify } from 'react-admin';
import IconUndo from '@material-ui/icons/Undo';

import IconButton from './IconButton';

import { UNARCHIVE } from '../../provider';

const ArchiveButton = ({ record, ...props }) => {
	const refresh = useRefresh();
	const notify = useNotify();

	const [unarchive, { loading }] = useMutation(
		{
			type: UNARCHIVE,
			resource: props.resource,
			payload: { id: record.id }
		},
		{
			onSuccess: ({ data }) => {
				refresh();
				notify(`1 unarchived element`);
			},
			onFailure: (error) => {
				notify(error.message, 'warning');
			}
		}
	);

	return (
		record.archived_at ? (
			<IconButton onClick={unarchive} icon={<IconUndo />} disabled={loading}>Unarchive</IconButton>
		) : (
			<DeleteButton record={record} {...props} undoable={false} label="Archive" />
		)
	);
};

export default ArchiveButton;