import Phone from '@material-ui/icons/Phone';

import LineList from './LineList';
import LineShow from './LineShow';

export default {
	list: LineList,
	show: LineShow,
	icon: Phone,
};
