import React from 'react';
import {
    TextInput,
    BooleanInput,
} from 'react-admin';

import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import SimPoolRow from './SimPoolRow';


const SimPoolFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <BooleanInput label="Archived" source="archived" alwaysOn />
    </Filter>
);

const filterDefaultValues = {
    archived: false,
}

const SimPoolActions = (props) => (
    <ListActions {...props}>
    </ListActions>
);

const SimPoolList = props => (
    <List {...props } filters={<SimPoolFilter/>} actions={<SimPoolActions />} perPage={50} filterDefaultValues={ filterDefaultValues }>
        <SimPoolRow />
    </List>
);

export default SimPoolList;