import React, { Component, Children, cloneElement, Fragment } from 'react';
import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { Link, linkToRecord } from 'react-admin';

const styles = {
	link: {
		cursor: 'pointer',

		'& *': {
			cursor: 'pointer',
			color: 'inherit'
		},
	},
};

const sanitizeRestClasses = ({ link, ...rest }) => rest;
const sanitizeRestProps = ({
	classes,
	to,
	relative,
	disabled,
	history,
	location,
	match,
	staticContext,
	...rest
}) => rest;

class LinkFieldButton extends Component {
	static propTypes = {
		basePath: PropTypes.string,
		children: PropTypes.any,
		record: PropTypes.object,
		classes: PropTypes.object,
		to: PropTypes.string,
		relative: PropTypes.bool,
		disabled: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
		match: PropTypes.object,
		source: PropTypes.string
	};
	static defaultProps = {
		to: 'show'
	};

	render() {
		let { record = {} } = this.props;
		const {
			basePath = '',
			children,
			classes,
			to,
			relative,
			disabled,
			match,
			source
		} = this.props;

		if (source) {
			record = record[source];
		}

		const recordLink = to.startsWith('/')
			? to.replace(/\/:([\w-]+)/g, (m, param) => {
				return `/${record[param]}`;
			})
			: linkToRecord(basePath, record?.id, to);
		const completeTo = relative ? `${match.url}${recordLink}` : recordLink;
		const rest = sanitizeRestProps(this.props);
		const restClasses = sanitizeRestClasses(classes);
		const isDisabled =
			typeof disabled === 'function' ? disabled(record) : disabled;

		const countChildren = Children.count(children);

		const childElements =
			countChildren === 1
				? cloneElement(children, {
					record,
					basePath,
					classes: restClasses,
				})
				: Children.map(children, field =>
					cloneElement(field, {
						record,
						basePath,
						classes: restClasses,
						...rest,
					})
				);

		return isDisabled ? (
				<Fragment>{childElements}</Fragment>
			) : (
				<Link to={`${completeTo}`} className={classes.link}>
					{childElements}
				</Link>
			);
	}
}

const enhance = compose(
	withRouter,
	withStyles(styles),
	shouldUpdate(
		(props, nextProps) =>
			props.translate !== nextProps.translate ||
			(props.record &&
				nextProps.record &&
				props.record.id !== nextProps.record.id) ||
			props.basePath !== nextProps.basePath ||
			(props.record == null && nextProps.record != null)
	)
);

export default enhance(LinkFieldButton);