import React from 'react';
import { TextField, Link, useTranslate } from 'react-admin';
import PhoneIcon from '@material-ui/icons/Phone';

import LinkFieldButton from '../../../common/LinkFieldButton';
import DateField from '../../../common/fields/DateField';
import NumberCurrencyField from '../../../common/fields/NumberCurrencyField';
import ServiceCard from './ServiceCard';
import StatusField from './StatusField';

const DataServiceCard = ({ record }) => {
	const translate = useTranslate();

	return (
		<Link to={`/services/${record.id}/show`}>
			<ServiceCard
				header={
					<>
						{translate('LINE')}
						{record.line ? (
							<>
								{` - `}
								<LinkFieldButton label="Line" source="line" basePath="/lines" record={record}>
									<TextField source="subscription_id" />
								</LinkFieldButton>
							</>
						) : ''}
						{record?.line?.provider_name ? ` - ${record.line.provider_name}` : ''}
					</>
				}
				title={
					<LinkFieldButton record={record} basePath="/services">
						<TextField source="name" />
					</LinkFieldButton>
				}
				content={<>{translate('Price')}: <NumberCurrencyField source="price" record={record} /></>}
				footer={
					record?.start_date ? (
						<>
							<DateField source="start_date" record={record} /> - {record.end_date ? <DateField source="end_date" record={record} /> : translate('Running')}
							<StatusField record={record} />
						</>
					) : <StatusField record={record} />
				}
				icon={<PhoneIcon />}
			/>
		</Link>
	);
};

export default DataServiceCard;
