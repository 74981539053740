import React from 'react';
import { DeleteWithConfirmButton } from 'react-admin';

const SimPoolDeleteButton = ({ record }) => {
    if (record.sims_count === 0) {
        return <DeleteWithConfirmButton record={record} />
    }

    return null;
};

export default SimPoolDeleteButton;