import React from 'react';
import { TextField, ReferenceField, FunctionField, ShowButton } from 'react-admin';

import Datagrid from '../common/Datagrid';
import LinkFieldButton from '../common/LinkFieldButton';
import SplitButton from '../common/SplitButton';
import MoveToPoolButton from './SimCardActionsButtons/MoveToPoolButton';
import StatusField from './StatusField';


// const SimCardDrawer = props => (
// 	<SimpleShowLayout {...props}>
// 		<TextField source="id" />
// 		<ReferenceField label="SimPool" source="pool_id" reference="simpools" link="show" allowEmpty>
// 			<TextField source="name" />
// 		</ReferenceField>
// 		<FunctionField label="iccid" source="iccid" render={record => `${record.iccid_formatted}`} />
// 		<TextField source="imsi" />
// 		<TextField source="provider_name" />
// 		<StatusField />
// 	</SimpleShowLayout>
// );

const CustomSplitButton = (props) => (
	<SplitButton>
		<ShowButton {...props} />
		<MoveToPoolButton {...props} />
	</SplitButton>
);

const SimCardRow = ({ children, childrenBefore = false, ...props }) => (
	<Datagrid {...props} basePath="/simcards">
		{childrenBefore && children}
		<LinkFieldButton label="iccid" sortBy="iccid">
			<FunctionField source="iccid" render={record => `${record.iccid_formatted}`} />
		</LinkFieldButton>
		<TextField source="provider_name" />
		<ReferenceField label="SimPool" source="pool_id" reference="simpools" link="show" allowEmpty>
			<TextField source="name" />
		</ReferenceField>
		<StatusField />
		<CustomSplitButton />
		{!childrenBefore && children}
	</Datagrid>
);

export default SimCardRow;