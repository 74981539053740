import React from 'react';
import { TextField } from 'react-admin';

import Datagrid from '../common/Datagrid';


const DataPricingRow = ({ children, ...props })  => (
	<Datagrid {...props}>
		<TextField source="provider" />
		<TextField source="country_code" />
		<TextField source="vpmn" label="Network"/>
		<TextField source="imsi" />
		<TextField label="Price/MB" source="price" options={{ style: 'currency', currency: 'EUR' }} />
		{children}
	</Datagrid>
);

export default DataPricingRow;
