import React from 'react';
import { InfoWindow } from 'react-google-maps';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
    tooltip: {
        '& a': {
            outline: 'none'
        }
    },
    closeIcon: {
        position: 'absolute',
        right: 0,
        top: 0,
        fontSize: '16px',
        color: '#CCC',
        cursor: 'pointer',
        padding: '2px',
        '&:hover': {
            color: 'black'
        }
    }
}));

const Tooltip = ({ children, onCloseClick, ...props }) => {
    const classes = useStyles();

    return (
        <InfoWindow {...props} options={{ headerDisabled: true }} onCloseClick={onCloseClick}>
            <div className={classes.tooltip}>
                <CloseIcon onClick={onCloseClick} className={classes.closeIcon} />
                {children}
            </div>
        </InfoWindow>
    );
};

export default Tooltip;