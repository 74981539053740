import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush } from 'recharts';

import GraphTooltip from '../common/GraphTooltip';


const CustomToolTip = ({ active, payload, label, groupBy }) => {
	if (!active || !payload) {
		return null;
	}

	return (
		<GraphTooltip>
			<p>
				<strong>{label}</strong>
			</p>
			{payload.map((item, i) => (
				<React.Fragment key={i}>
					<p>Usage: <strong>{item.value} GB</strong></p>
					{(item.payload.customer && groupBy !== 'customer') && (<p>Customer: {item.payload.customer}</p>)}
					{(item.payload.site && groupBy !== 'site') && (<p>Site: {item.payload.site}</p>)}
					{(item.payload.device && groupBy !== 'device') && (<p>Device: {item.payload.device}</p>)}
					{(item.payload.pool && groupBy !== 'pool') && (<p>Pool: {item.payload.pool}</p>)}
				</React.Fragment>
			))}
		</GraphTooltip>
	);
};

const UsageReportGraph = ({ data, groupBy, small }) => {
	let xAxisDataKey = groupBy;
	if (['iccid_in_pool', 'iccid_in_device'].includes(groupBy)) {
		xAxisDataKey = 'iccid';
	}

	return (
		<ResponsiveContainer width="100%" height={small ? 200 : 400}>
			<BarChart
				data={data}
				margin={{
					top: 50, right: 30, left: 20, bottom: 20,
				}}
				maxBarSize={100}
			>
				<CartesianGrid strokeDasharray="10 5" vertical={false} />
				<XAxis dataKey={xAxisDataKey} />
				<YAxis dataKey="usage" unit="GB" />
				<Tooltip content={<CustomToolTip groupBy={groupBy} />} />
				<Legend wrapperStyle={{ bottom: 5 }} />
				<Bar name="Data Usage" dataKey="usage" fill="#3A8B7E" radius={3} />
				<Brush dataKey={groupBy} height={30} travellerWidth={10} startIndex={0} endIndex={data.length > 10 ? 9 : data.length - 1} stroke="#3A8B7E" />
			</BarChart>
		</ResponsiveContainer>
	);
};

UsageReportGraph.defaultProps = {
	groupBy: 'device'
};

export default UsageReportGraph;
