import React from 'react';
import {
	Datagrid,
	TextField,
	ShowButton,
	TextInput
} from 'react-admin';

import DateField from '../common/fields/DateField';
import List from '../common/List';
import Filter from '../common/Filter';


const UserFilter = (props) => (
	<Filter {...props}>
		<TextInput label="Search" source="q" alwaysOn />
	</Filter>
);

const UserList = props => (
	<List {...props} filters={<UserFilter />}>
		<Datagrid>
			<TextField source="email" />
			<TextField source="firstname" />
			<TextField source="lastname" />
			<DateField label="Access at" source="last_login_at" showTime />
			<ShowButton />
		</Datagrid>
	</List>
);

export default UserList;