import React from 'react';
import { SelectInput } from 'react-admin';


const choices = [
	{ id: 'CONFIRMED', name: 'CONFIRMED' },
	{ id: 'ON HOLD', name: 'ON HOLD' },
	{ id: 'WAITING FOR SSV', name: 'WAITING FOR SSV' },
	{ id: 'SSV DATE FIXED', name: 'SSV DATE FIXED' },
	{ id: 'SSV DONE', name: 'SSV DONE' },
	{ id: 'ORDER SEND TO SUPPLIER', name: 'ORDER SEND TO SUPPLIER' },
	{ id: 'INSTALL REFUSED BY SUPPLIER', name: 'INSTALL REFUSED BY SUPPLIER' },
	{ id: 'PREORDER SEND TO SUPPLIER', name: 'PREORDER SEND TO SUPPLIER' },
	{ id: 'QUOTE SEND TO CUSTOMER', name: 'QUOTE SEND TO CUSTOMER' },
	{ id: 'QUOTE APPROVED BY CUSTOMER', name: 'QUOTE SEND TO CUSTOMER' },
	{ id: 'QUOTE REFUSED BY CUSTOMER', name: 'QUOTE REFUSED BY CUSTOMER' },
	{ id: 'INSTALL DATE FIXED', name: 'INSTALL DATE FIXED' },
	{ id: 'INSTALLED', name: 'INSTALLED' },
	{ id: 'PROBLEM STATE', name: 'PROBLEM STATE' },
	{ id: 'VALIDATED', name: 'VALIDATED' },
	{ id: 'CANCELLED', name: 'CANCELLED' },
	{ id: 'UNKNOWN STATUS', name: 'UNKNOWN STATUS' },
	{ id: 'TO CANCEL', name: 'TO CANCEL' }
];

const StatusField = ({ classes, record, source="status", ...props }) => {
	if (!record) {
		return null;
	}

	return (
		<SelectInput choices={choices} source={source} record={record} {...props} />
	);
};

export default StatusField;
