import React from 'react';
import { useTranslate } from 'react-admin'
import GetAppIcon from '@material-ui/icons/GetApp';

import IconButton from '../../common/IconButton';

import { API_URL } from '../../../services/settings';

const ExportStatsButton = ({ record }) => {
    const translate = useTranslate();

    if (!record) {
        return null;
    }

    return (
        <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/starlink_terminals/${record.id}/stats/export.xls`}>{translate('Stats export')}</IconButton>
    );
};

export default ExportStatsButton;