import React from 'react';
import { List as ReactAdminList, Pagination, CreateButton, ExportButton } from 'react-admin';
import { Helmet } from 'react-helmet';

import CardActions from './CardActions';


const ListActions = ({ basePath, bulkActions, filterValues, selectedIds, onUnselectItems, filters, showFilter, displayedFilters, total, currentSort, exporter, className, children, resource, hasCreate }) => {
	return (
		<CardActions className={className}>
			{bulkActions && React.cloneElement(bulkActions, {
				basePath,
				filterValues,
				resource,
				selectedIds,
				onUnselectItems,
			})}
			{filters && React.cloneElement(filters, {
				resource,
				showFilter,
				displayedFilters,
				filterValues,
				context: 'button',
			})}
			{children}
			{hasCreate && <CreateButton basePath={basePath} />}
			{exporter && (
				<ExportButton
					disabled={total === 0}
					resource={resource}
					sort={currentSort}
					filter={filterValues}
					exporter={exporter}
					maxResults={5000}
				/>
			)}
		</CardActions>
	);
};

const BulkActions = ({ dataProvider, ...props }) => {
	return (
		<>
		</>
	);
};

const ListPagination = props => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />

const List = props => (
	<>
		<Helmet>
			<title>KB - {props.resource.charAt(0).toUpperCase() + props.resource.slice(1)}</title>
		</Helmet>
		<ReactAdminList {...props} />
	</>
);

List.defaultProps = {
	actions: <ListActions />,
	bulkActionButtons: false,
	pagination: <ListPagination />,
	perPage: 25,
	sort:{ field: 'id', order: 'DESC' },
	exporter: false
};

export default List;
export { ListActions, BulkActions, ListPagination };