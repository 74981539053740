import React from 'react';
import {
    TextInput,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';

import List from '../common/List';
import Filter from '../common/Filter';
import LineRow from './LineRow';
import StatusField from './StatusField';
import LineTypeField from './LineTypeField';

const optionText = choice => choice.name ? choice.name : '';

const LineFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <StatusField label="Status" source="status" alwaysOn />
        <ReferenceInput label="Provider" source="provider_id" reference="line_providers" sort={{ field: 'name', order: 'ASC' }} allowEmpty alwaysOn>
            <AutocompleteInput optionText={optionText} />
        </ReferenceInput>
        <LineTypeField label="Type" source="line_type" alwaysOn />
    </Filter>
);

const LineList = props => (
    <List {...props} filters={<LineFilter />} >
        <LineRow />
    </List>
);

export default LineList;