import React from 'react';
import { TextField, Link, useTranslate } from 'react-admin';
import RouterIcon from '@material-ui/icons/Router';

import LinkFieldButton from '../../../common/LinkFieldButton';
import DateField from '../../../common/fields/DateField';
import NumberCurrencyField from '../../../common/fields/NumberCurrencyField';
import ServiceCard from './ServiceCard';
import StatusField from './StatusField';


const SDWANServiceCard = ({ record }) => {
	const translate = useTranslate();

	return (
		<Link to={`/services/${record.id}/show`}>
			<ServiceCard
				header={
					<>
						SDWAN
						{record.device ? (
							<>
								{` - `}
								<LinkFieldButton label="Device" source="device" basePath="/devices" record={record}>
									<TextField source="serial" />
								</LinkFieldButton>
							</>
						) : ''}
					</>
				}
				title={
					<LinkFieldButton record={record} basePath="/services">
						<TextField source="name" />
					</LinkFieldButton>
				}
				content={<>{translate('Price')}: <NumberCurrencyField source="price" record={record} /></>}
				footer={
					record?.start_date ? (
						<>
							<DateField source="start_date" record={record} /> - {record.end_date ? <DateField source="end_date" record={record} /> : translate('Running')}
							<StatusField record={record} />
						</>
					) : <StatusField record={record} />
				}
				icon={<RouterIcon />}
			/>
		</Link>
	);
};

export default SDWANServiceCard;
