import PackageList from './PackageList';
import PackageShow from './PackageShow';
import PackageIcon from './PackageIcon';


export default {
	list: PackageList,
	show: PackageShow,
	icon: PackageIcon,
	title: 'Packages',
};
