import React from 'react';
import { useTranslate } from 'react-admin';
import { Tooltip } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

import ChipField from './ChipField';

const BooleanField = ({ record, source, valueLabelTrue, valueLabelFalse, reverse, chip, ...props }) => {
    const translate = useTranslate();

    if (!record || record[source] === null || record[source] === undefined) {
        return null;
    }

    const value = record ? (reverse ? !record[source] : record[source]) : null;
    const label = record[source] === false ? (valueLabelFalse || 'ra.boolean.false') : (valueLabelTrue || 'ra.boolean.true');
    const color = value === false ? red[100] : green[100];
    const iconStyle = { color };

    if (chip) {
        return (
            <ChipField source={source} record={{ [source]: translate(label, { _: label }) }} color={color} {...props} />
        );
    }

    return (
        <Tooltip title={translate(label, { _: label })}>
            <FiberManualRecordIcon style={iconStyle} />
        </Tooltip>
    );
};

BooleanField.defaultProps = {
    addLabel: true,
    reverse: false,
    chip: true
};

export default BooleanField;
