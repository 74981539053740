import React from 'react';
import { TextField, ReferenceField } from 'react-admin';
import { makeStyles } from '@material-ui/core';

import Datagrid from '../common/Datagrid';
import ChipField from '../common/ChipField';
import DateField from '../common/DateField';
import StatusField from '../simcards/StatusField';
import '../simcards/SimCardRow';
import rowStyle from './rowStyle';

import LineRow from '../lines/LineRow';

const useStyles = makeStyles({
	table: {
		marginBottom: '10px',

		'&:last-child td': {
			borderBottom: 'none'
		}
	}
});

const SimCardList = ({ record, ...props }) => {
	const classes = useStyles();

	if (!record.sim_a && !record.sim_b && !record.line_id) {
		return null;
	}

	let data = {};
	let ids = [];

	if (record.line) {
		data[record.line.id] = record.line
		ids.push(record.line_id);

		return <LineRow {...props} data={data} ids={ids} classes={classes} />;
	}
	else {
		if (record.sim_a) {
			data[record.sim_a.id] = record.sim_a;
			ids.push(record.sim_a_id);
		}

		if (record.sim_b) {
			data[record.sim_b.id] = record.sim_b;
			ids.push(record.sim_b_id);
		}

		return (
			<Datagrid {...props} data={data} ids={ids} classes={classes}>
				<TextField source="iccid_formatted" label="iccid" />
				<TextField source="provider_name" />
				<ReferenceField label="SimPool" source="pool_id" reference="simpools" link="show" allowEmpty>
					<TextField source="name" />
				</ReferenceField>
				<StatusField />
			</Datagrid>
		);
	}
};

const WanRow = ({ children, ...props }) => (
	<Datagrid expand={<SimCardList {...props} />} rowStyle={rowStyle} {...props}>
		<TextField source="name" />
		<TextField label="Status" source="ic_status" />
		<TextField source="ip_address" />
		<ChipField label="Network type" source="cellular_network_type" color="primary" size="small" />
		<DateField source="updated_at" />
		{children}
	</Datagrid>
);

export default WanRow;
