import React from 'react';
import { TextInput, ReferenceInput, AutocompleteInput, ShowButton } from 'react-admin';

import List, { ListActions } from '../common/List';
import IconButton from '../common/IconButton';
import Filter from '../common/Filter';
import SplitButton from '../common/SplitButton';
import DeviceRow from './DeviceRow';
import RebootDeviceButton from './DeviceActionsButtons/RebootDeviceButton';
import SyncDeviceButton from './DeviceActionsButtons/SyncDeviceButton';

import GetAppIcon from '@material-ui/icons/GetApp';

import { API_URL } from '../../services/settings';

const siteOptionText = choice => choice.name ? choice.name : '';

const DeviceListActions = (props) => (
    <ListActions {...props}>
        <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/devices/export.xls`}>Export</IconButton>
    </ListActions>
);

const DeviceFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Site" source="site_id" reference="sites" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
            <AutocompleteInput optionText={siteOptionText} />
        </ReferenceInput>
    </Filter>
);

const DeviceSplitButton = ({ record }) => (
    <SplitButton>
        <ShowButton record={record} />
        <RebootDeviceButton record={record} />
        <SyncDeviceButton record={record} />
    </SplitButton>
);

const DeviceList = ({ classes, ...props }) => (
    <List {...props} filters={<DeviceFilter />} actions={<DeviceListActions />} exporter={false} >
        <DeviceRow>
            <DeviceSplitButton />
        </DeviceRow>
    </List>
);

DeviceList.defaultProps = {
    bulkActionButtons: false,
    exporter: false
};

export default DeviceList;